import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./login.styles.scss";
import logo from "./../../assets/images/gigs-logo.svg";
import google from "./../../assets/images/google_icon.png";
import facebook from "./../../assets/images/facebook_icon.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Dots from "../../assets/images/dots.svg";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import {
  requestOtp,
  setStatusVerifyDefault,
  logout,
  setNewUser,
} from "../../redux/user/user.action";

import * as authService from "../../services/auth";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { useCookies } from "react-cookie";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, isLoading, message, statusVerify } = useSelector(
    (state) => state.user
  );
  const users = useSelector((state) => state.user);

  const [cookies, setCookies, removeCookies] = useCookies([
    "user",
    "subdomain",
  ]);
  const [email, setEmail] = useState("");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const responseSocialAuth = async (provider, response) => {
    try {
      const access_token = response?.accessToken;

      setFirstName(
        response.profileObj?.givenName ?? response?.name.split(" ")[0]
      );
      setLastName(
        response.profileObj?.familyName ?? response?.name.split(" ")[1]
      );
      setEmail(response.profileObj?.email ?? response?.email);

      if (access_token) {
        const result = await authService.loginWithSocmedMain(
          provider,
          access_token
        );
        if (result.data.data.token) {
          dispatch(setNewUser(result.data.data));
          history.push("/choose-studio");
        }
      }
    } catch (error) {
      if (error.response.data.message === "User not found") {
        setShowModalRegister(true);
      } else {
        toast.error(<GenerateErrorMessage error={error} />);
      }
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value.trim());
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(requestOtp(email));
    },
    [dispatch, email]
  );

  const redirectToRegister = () => {
    history.push({
      pathname: "/register",
      state: { firstName: firstName, lastName: lastName, email: email },
    });
  };

  useEffect(() => {
    if (message === "User not registered") {
      setShowModalRegister(true);
      dispatch(setStatusVerifyDefault());
    }
  }, [message]);

  useEffect(() => {
    if (statusVerify === "success") {
      history.push("/verify");
      dispatch(setStatusVerifyDefault());
    }
  }, [statusVerify]);

  useEffect(() => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }

    if (user.token && firstName === "") {
      dispatch(logout(user?.token));
    }
  }, [user.token]);

  return (
    <div>
      <div className="md:flex min-h-screen">
        <div className="login-left-image md:w-3/5">
          <img
            src={Dots}
            className="w-40 absolute top-[230px] -left-[100px] z-0 md:top-[75%]"
            alt="dots"
          />
          <img
            src={Dots}
            className="w-40 absolute top-0 right-0 md:right-[40%] z-0 "
            alt="dots"
          />
          <div className="text-white text-title w-full flex justify-between">
            Gigs
          </div>
          <div
            style={{ height: "100%" }}
            className="d-flex flex-column justify-content-center align-items-center flex-wrap text-white"
          >
            <img src={logo} alt="logo" className="w-20 md:w-52 z-10" />
            <h4 className="mt-4 text-center">WELCOME TO GIGSCLASS</h4>
            <div className="md:w-96 text-center text-[#BAC2DB] flex">
              <div>
                <p>
                  One app fit for the entire studios. Making learn fun and
                  exciting
                </p>
                <p>Flexible learning inspired by the pandemic</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:w-2/5">
          <div className="login-content  p-5">
            <h1 className="heading1 mt-4 mb-2 fw-bold">Sign In</h1>
            <div className="d-flex flex-column my-5">
              <span className="fs-12 ">
                If you don’t have an account register
              </span>
              <span className="fs-12">
                You can{" "}
                <a
                  href="/register"
                  className="fw-bold"
                  style={{ color: "#E26851", textDecoration: "none" }}
                >
                  Register here !
                </a>
              </span>
            </div>

            <Form className="w-100 mb-5" onSubmit={handleSubmit}>
              <TextInput
                required
                autoFucus
                label="Email Address"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value.trim())}
              />
              <Button
                buttonType="submit"
                title="Login"
                type="primary"
                className="mt-4"
                style={{
                  width: "100%",
                  backgroundColor: "#E26851",
                  borderRadius: 0,
                }}
                // isLoading={isLoading}
              />
            </Form>
            <div className="d-flex justify-content-center flex-warp align-items-center flex-column">
              <div className="custom-form-label mt-3 mb-3">
                or continue with
              </div>
              <div className="flex items-center justify-center">
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  size="small"
                  render={(renderProps) => (
                    <img
                      className="cursor-pointer"
                      onClick={renderProps.onClick}
                      style={{
                        height: "25px",
                        width: "25px",
                        marginRight: "10px",
                      }}
                      src={facebook}
                      alt="logo"
                    />
                  )}
                  fields="name,email,picture"
                  callback={(res) => responseSocialAuth("facebook", res)}
                  cssClass="facebook-btn mt-3 custom-hover"
                />
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => {
                    return (
                      <img
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="google-btn custom-hover"
                        style={{
                          height: "25px",
                          width: "25px",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        src={google}
                        alt="img"
                      />
                    );
                  }}
                  onSuccess={(res) => responseSocialAuth("google", res)}
                  onFailure={(res) => responseSocialAuth("google", res)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal size="sm" className="w-100" show={showModalRegister} centered>
        <Row>
          <Col lg={12} className="p-4 text-center">
            <h2 className="heading2 mb-3">Email Not Registered</h2>
            <span className="custom-form-label">
              Continue register with
            </span>{" "}
            <br />
            <b className="fs-12">{email}</b>
            <div className="d-flex flex-column flex-warp  align-items-center">
              <Button
                title="CONFIRM"
                type="primary"
                className="mt-2"
                onClick={redirectToRegister}
                isLoading={isLoading}
                style={{
                  backgroundColor: "#E26851",
                  borderRadius: 0,
                  padding: "4px 8px",
                  height: 40,
                }}
              />

              <Button
                title="CHANGE"
                type="secondary"
                className="mt-2"
                onClick={() => setShowModalRegister(false)}
                isLoading={isLoading}
                style={{ color: "#E26851" }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Login;
