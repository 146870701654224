import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { Container, Row, Col, Form } from "react-bootstrap";

import Button from "../../../../components/Button/button.component";

import { useCookies } from "react-cookie";
import { addUpdatePayment } from "../../../../redux/owner/owner.action";
import AtomLoading from "../../../../components/loding-indicator";
import OnboardingStatus from "../../../../components/onboarding-status";
import { setMenuActive } from "../../../../redux/user/user.action";
import {
  connectToStripe,
  getBanksCode,
  getDisbursementSetting,
} from "../../../../services/payment";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import {
  Col,
  ConfigProvider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import oyLogo from "../../../../assets/images/oy-logo.svg";
import stripeLogo from "../../../../assets/images/stripe-logo.svg";

const SettingPayment = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [cookies, setCookies] = useCookies(["user"]);
  const [paymentCode, setPaymentCode] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [disabledChange, setDisabledChange] = useState();

  const { isLoadingPayment } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const isOnboarding = props.location.state?.isOnboarding;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  useEffect(() => {
    handleGetPaymentCode();
    handleGetDisbursementSetting();
    // dispatch(getPaymentDetail(user_token, studio_id));
    // dispatch(getListBanks(user_token, studio_id));
    dispatch(setMenuActive("Settings"));
  }, []);

  // useEffect(() => {
  //   if (disbursementSetting) {
  //     const {
  //       recipient_bank_code,
  //       recipient_account,
  //       recipient_name,
  //       payment_gateway,
  //     } = disbursementSetting;

  //     form.setFieldsValue({
  //       recipient_bank_code,
  //       recipient_account,
  //       recipient_name,
  //     });
  //     setSelectedPayment(payment_gateway);
  //   }
  // }, [disbursementSetting, form]);

  const return_url = window.location.href;

  const handleConnectStripe = async () => {
    try {
      dispatch(
        addUpdatePayment(
          { payment_gateway: selectedPayment },
          user_token,
          studio_id
        )
      );

      const { data } = await connectToStripe(user_token, studio_id, return_url);
      const url = data.data.url;

      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePayment = (e) => {
    setSelectedPayment(e.target.value);
  };

  const handleGetDisbursementSetting = async () => {
    try {
      const { data } = await getDisbursementSetting(user_token, studio_id);
      const { is_payment_gateway_editable, payment_gateway } = data.data;

      setIsChange(!payment_gateway || payment_gateway.length === 0);
      setSelectedPayment(payment_gateway);
      setDisabledChange(is_payment_gateway_editable);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPaymentCode = async () => {
    try {
      const res = await getBanksCode(user_token, studio_id);
      const optCodeBank = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      });
      setPaymentCode(optCodeBank);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = ({
    recipient_bank_code,
    recipient_account,
    recipient_name,
  }) => {
    const params = {
      recipient_bank_code,
      recipient_account,
      recipient_name,
      payment_gateway: selectedPayment,
    };

    dispatch(addUpdatePayment(params, user_token, studio_id));
    handleGetDisbursementSetting();
    setIsChange(false);
  };

  return (
    <OrganismLayout>
      <div className=" min-h-screen">
        {isOnboarding && <OnboardingStatus selectedStep={2} activeStep={7} />}
        {isLoadingPayment ? (
          <AtomLoading />
        ) : (
          <div>
            <h2 className="font-bold ">Setup payment gateway</h2>

            <div
              className="px-4 py-2 md:w-[568px] mt-14"
              style={{
                background: hexToRGBA("#FFCD0033", 0.2),
                border: `1px solid ${hexToRGBA("#FFCD0033", 0.3)}`,
              }}
            >
              {isChange ? (
                <span>
                  You can't change the payment gateway after a transaction has
                  been made at your studio
                </span>
              ) : (
                <p>
                  You are currently connected to{" "}
                  <b className="capitalize">{selectedPayment}</b>!. You can't
                  change the payment gateway after a transaction has been made
                  at your studio
                </p>
              )}

              {isChange ? null : (
                <Button
                  style={{
                    backgroundColor: colorTheme,
                    borderRadius: 0,
                  }}
                  type="primary"
                  title="Change"
                  buttonType="submit"
                  onClick={() => setIsChange(true)}
                />
              )}
            </div>

            <div className="text-lg font-bold my-4">
              {isChange ? "Input your bank account to payout" : null}
            </div>

            {isChange ? (
              <div className="text-lg font-bold my-4">
                Choose Payment Gateway
              </div>
            ) : null}

            {isChange ? (
              <div
                className="px-4 py-2 md:w-[568px] mb-4"
                style={{
                  background: hexToRGBA("#0D47A1", 0.1),
                  border: `1px solid ${hexToRGBA("#0D47A1", 0.2)}`,
                }}
              >
                <span>
                  The payment gateway you choose will be used for all
                  transactions in your studio
                </span>
              </div>
            ) : (
              <Space direction="vertical" className="mb-4">
                <span>{`${isChange ? "Choose" : ""} Payment Gateway`}</span>
                <img
                  src={selectedPayment === "oy" ? oyLogo : stripeLogo}
                  alt=""
                />
              </Space>
            )}

            {isChange ? (
              <Radio.Group onChange={onChangePayment} value={selectedPayment}>
                <div
                  className="p-4 md:w-[568px] mb-4 flex items-center gap-4"
                  style={{
                    border: `1px solid ${hexToRGBA("#E0E0E0", 1)}`,
                  }}
                >
                  <Radio
                    value="oy"
                    style={{
                      "--active-color": colorTheme,
                    }}
                  >
                    <img src={oyLogo} alt="" /> <br />
                    <span>{`Recommendation for IDR currency`}</span>
                  </Radio>
                </div>

                <div
                  className="p-4 md:w-[568px] mb-4 flex items-center gap-4"
                  style={{
                    border: `1px solid ${hexToRGBA("#E0E0E0", 1)}`,
                  }}
                >
                  <Radio
                    value="stripe"
                    style={{
                      "--active-color": colorTheme,
                    }}
                  >
                    <img src={stripeLogo} alt="" /> <br />
                    <span>{`Recommendation for USD currency`}</span>
                  </Radio>
                </div>
              </Radio.Group>
            ) : null}

            {selectedPayment === "oy" ? (
              <ConfigProvider
                theme={{
                  components: {
                    Form: {
                      /* here is your component tokens */
                    },
                  },
                }}
              >
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  layout="vertical"
                  className="w-full md:w-[568px]"
                  variant={isChange ? "outlined" : "borderless"}
                  disabled={!isChange}
                >
                  <Form.Item
                    required={isChange}
                    label="Choose Bank Account"
                    name="recipient_bank_code"
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={paymentCode}
                      className="h-10"
                      placeholder="Choose Bank Name"
                    />
                  </Form.Item>
                  <Form.Item
                    required={isChange}
                    label="Account Holder Name"
                    name="recipient_name"
                  >
                    <Input
                      placeholder="Input Your Account Holder Name"
                      className="h-10"
                    />
                  </Form.Item>
                  <Form.Item
                    required={isChange}
                    label="Account Number"
                    name="recipient_account"
                  >
                    <Input
                      type="number"
                      placeholder="Input Your Account Holder Name"
                      className="h-10"
                    />
                  </Form.Item>

                  <div className="flex justify-between">
                    <Button
                      type="outline-secondary"
                      title={isOnboarding ? "Skip for Now" : "Cancel"}
                      onClick={() => {
                        setIsChange(false);
                        history.push(
                          isOnboarding ? "/admin/dashboard" : "/admin/settings"
                        );
                      }}
                      style={{
                        border: `1px solid ${colorTheme}`,
                        color: colorTheme,
                        borderRadius: 0,
                      }}
                    />

                    <Button
                      style={{
                        backgroundColor: colorTheme,
                        borderRadius: 0,
                      }}
                      type="primary"
                      title="SAVE"
                      buttonType="submit"
                    />
                  </div>
                </Form>
              </ConfigProvider>
            ) : selectedPayment === "stripe" ? (
              <div className="flex justify-between md:w-[568px]">
                <Button
                  type="outline-secondary"
                  title={"Cancel"}
                  onClick={() => {
                    setIsChange(false);
                    history.push("/admin/settings");
                  }}
                  style={{
                    border: `1px solid ${colorTheme}`,
                    color: colorTheme,
                    borderRadius: 0,
                  }}
                />

                {isChange ? (
                  <Button
                    style={{
                      backgroundColor: colorTheme,
                      borderRadius: 0,
                    }}
                    type="primary"
                    title="Connect"
                    onClick={handleConnectStripe}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </OrganismLayout>
  );
};

export default SettingPayment;
