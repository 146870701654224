import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { useAccordionButton } from "react-bootstrap/AccordionButton";

import "./../students.styles.scss";

import { useCookies } from "react-cookie";
import {
  cancelClass,
  getActivePlan,
  getClasses,
  getProfile,
  getUserRole,
  setStatusDefault,
} from "../../../../redux/student/student.action";
import AtomLoading from "../../../../components/loding-indicator";
import {
  getClassDetail,
  getPaymentDetail,
} from "../../../../redux/owner/owner.action";
import { toast } from "react-toastify";
import {
  getCancellationSetting,
  getClientKey,
} from "../../../../redux/studio/studio.action";
import { changeRole } from "../../../../services/auth";
import {
  setLoginFromStudio,
  setMenuActive,
  setNewUser,
  getStudioDetail,
} from "../../../../redux/user/user.action";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { continuePayment } from "../../../../services/payment";
import Sidebar from "../../../../components/SidebarOwner";
import {
  Col,
  ConfigProvider,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  Modal,
  Input,
  Checkbox,
} from "antd";
import { CaretDownOutlined, CheckOutlined } from "@ant-design/icons";
import calender from "../../../../assets/images/calender.svg";
import { DateRange } from "react-date-range";
import Button from "../../../../components/Button/button.component";
import hexToRGBA from "../../../../helper/hexToRbga";
import Dot from "../../../../assets/images/3dots.svg";
import WellcomeOwner from "../../../Owner/welcome.component";
import CustomButton from "../../../../components/Button/custom-button";

const { Text } = Typography;

const statusColors = {
  upcoming: "#0296CF",
  registered: "#34A853",
  waiting_list: "#F9B032",
  pending: "#EF6236",
  Unattended: "#EF4136",
  Attended: "#34A853",
};

const StudentDashBoard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isRegisterStudent } = useSelector((state) => state.studio);

  const [active, setActive] = useState(1);
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(isRegisterStudent);
  const [cookies, setCookies, removeCookies] = useCookies(["user"]);
  const [status, setStatus] = useState("registered");
  const [selectedDate, setSelectedDate] = useState({ visible: false });
  const [action, setAction] = useState({
    isOpenDetail: false,
    isCancelClass: false,
    isConfirmPayment: false,
    selectedClass: {},
    isChecked: false,
    notes: "",
    showPolicy: false,
    cancelClassSubmitted: false,
  });

  let date = new Date();
  let last = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);

  const [state, setState] = useState([
    {
      startDate: last,
      endDate: date,
      key: "selection",
    },
  ]);

  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const { user_role, profile, activePlan, classes, cancel_class_status } =
    useSelector((state) => state.student);
  const { class_detail } = useSelector((state) => state.owner);
  const { cancellation_setting } = useSelector((state) => state.studio);

  const payment_pending = props.location?.state;

  const role =
    loginFromStudio || isRegisterStudent ? user?.role : cookies.user?.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const userRoles = user_role.roles
    ? user_role.roles?.filter((item) => item !== role)
    : [];

  useEffect(() => {
    dispatch(getCancellationSetting(user_token, studio_id));
  }, []);

  useEffect(() => {
    if (cancel_class_status === "success") {
      toast.success("Successfully submitted");
      setAction((prev) => ({
        ...prev,
        isCancelClass: false,
        cancelClassSubmitted: true,
      }));
    }
    dispatch(setStatusDefault);
  }, [cancel_class_status]);

  const setRoles = async (role) => {
    try {
      const params = {
        role: role,
      };

      const res = await changeRole(user_token, studio_id, params);
      dispatch(setNewUser(res.data.data));

      // if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      // }
      dispatch(setLoginFromStudio());
      history.push("/dashboard");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const setPending = () => {
    if (payment_pending) setStatus("pending");
  };

  useEffect(() => {
    // setPending();
    dispatch(getUserRole(user_token, studio_id));
    dispatch(getProfile(user_token, studio_id));
    dispatch(getActivePlan(user_token, studio_id, "active"));
    dispatch(setMenuActive("Dashboard"));
  }, [dispatch, studio_id, user_token]);

  useEffect(() => {
    dispatch(
      getClasses(
        user_token,
        studio_id,
        status === "past" ? `passed=true` : `status=${status}`
      )
    );
  }, [status, active, dispatch, user_token, studio_id]);

  useEffect(() => {
    dispatch(getStudioDetail(user_token, studio_id));
  }, [studio_id, user_token, dispatch]);

  const content = (
    <div className="">
      <a href="/edit-profile" className="text-black text-decoration-none">
        Edit Profile
      </a>
    </div>
  );

  const actionList = [
    {
      label: "Detail",
      onClick: (e) => {
        setAction((prev) => ({
          ...prev,
          isOpenDetail: true,
          selectedClass: e,
        }));
        dispatch(getClassDetail(user_token, studio_id, e.class_id));
      },
    },
    status !== "past"
      ? {
          label: "Cancel class",
          onClick: (e) => {
            setAction((prev) => ({
              ...prev,
              isCancelClass: true,
              selectedClass: e,
            }));
            dispatch(getClassDetail(user_token, studio_id, e.class_id));
          },
          className: "text-[#EB5757]",
        }
      : {
          label: null,
        },
    status === "pending"
      ? {
          label: "Confrim Payment",
          onClick: (e) => {
            setAction((prev) => ({ ...prev, isConfirmPayment: true }));
          },
        }
      : { label: null },
  ];

  const columns = [
    {
      title: "Class Name",
      dataIndex: "class_name",
      key: "class_name",
      render: (text) => {
        return (
          <b
          // className=" cursor-pointer font-black"
          // onClick={() => {
          //   setSelectedClassDetail(record);
          //   showModalDetailClass();
          // }}
          >
            {text}
          </b>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "class_start_time",
      key: "class_start_time",
    },
    {
      title: "Teacher",
      dataIndex: "teachers",
      key: "teachers",
      render: (text) => <span>{text.join(", ")}</span>,
    },
    {
      title: "Type",
      dataIndex: "is_online",
      key: "is_online",
      render: (text) => {
        return text ? (
          <Tag color="#27AE60">Online</Tag>
        ) : (
          <Tag color="#2D9CDB">Onsite</Tag>
        );
      },
    },
    status !== "past"
      ? {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text) => {
            const color = statusColors[text];
            return (
              <Tag color={color} className="capitalize">
                {text}
              </Tag>
            );
          },
        }
      : {
          title: "Attendance",
          dataIndex: "is_attended",
          key: "is_attended",
          render: (text) => {
            const color = statusColors[text];
            return <Tag color={color}>{text}</Tag>;
          },
        },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Popover
          placement="bottomRight"
          content={() => (
            <Space direction="vertical" className="w-28">
              {actionList
                .filter((x) => x.label !== null)
                .map((item) => (
                  <span
                    onClick={() => item.onClick(record)}
                    className={`cursor-pointer my-2 custom-hover ${item.className}`}
                  >
                    {item.label}
                  </span>
                ))}
            </Space>
          )}
          className="cursor-pointer"
        >
          <img alt="" className="w-6 h-6" src={Dot} />
        </Popover>
      ),
    },
  ];

  const color = statusColors[action.selectedClass?.status];

  const handleSubmit = () => {
    const params = {
      cancellation_reason: action.notes,
    };

    dispatch(
      cancelClass(user_token, studio_id, params, action.selectedClass?.order_id)
    );
  };

  return (
    <Sidebar>
      <div style={{ minHeight: "100vh" }}>
        <div className="d-flex row">
          <div className="mx-auto mb-5 px-1">
            <div className="flex justify-between items-start">
              <div>
                <div className="flex items-center gap-2">
                  <span className="heading1 fw-bold">
                    {profile?.first_name} {profile?.last_name}
                  </span>
                  <Popover
                    open={open}
                    onOpenChange={() => setOpen((prev) => !prev)}
                    trigger="click"
                    placement="bottom"
                    content={content}
                  >
                    <CaretDownOutlined onClick={() => setOpen(true)} />
                  </Popover>
                </div>

                <span className="heading2 fw-normal text-capitalize">
                  {role === "owner" ? "admin" : role}
                </span>
              </div>

              <div className="flex items-center gap-6">
                <Space>
                  <img alt="" src={calender} width={18} />
                  <span
                    onClick={() => setSelectedDate({ visible: true })}
                    className=" pointer custom-hover ms-2"
                  >
                    {`${moment(state[0].startDate).format(
                      "DD MMM, YYYY"
                    )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
                  </span>
                  <CaretDownOutlined
                    onClick={() => setSelectedDate({ visible: true })}
                  />
                </Space>

                <div className="shadow-sm py-2 px-4">
                  <Space>
                    <span>Jakarta</span>
                    <CaretDownOutlined />
                  </Space>
                </div>
              </div>
            </div>

            {activePlan?.plans?.length > 0 ? (
              <>
                <Row justify="space-between" align="middle" className="mt-20">
                  <Col>
                    <h5>Plan</h5>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      title="Add Plan"
                      onClick={() => history.push("/buy-plan")}
                      style={{
                        borderRadius: 0,
                        backgroundColor: colorTheme,
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-2 mb-20" gutter={[16, 16]}>
                  {activePlan.plans?.map((el, idx) => (
                    <Col key={idx}>
                      <div
                        className="pl-6 py-4 w-[280px] h-full"
                        style={{
                          border: `1px solid ${hexToRGBA(colorTheme, 0.3)}`,
                        }}
                      >
                        <Space direction="vertical">
                          <div
                            className={`w-fit rounded-md text-white font-bold bg-[#4285F4] px-2 py-1 text-center`}
                          >
                            Membership
                          </div>
                          <h6 className="m-0">{el.plan_name}</h6>
                          <Text type="secondary">{el.expired_date}</Text>
                          <Text>Credit left: -</Text>
                        </Space>
                      </div>
                    </Col>
                  ))}
                </Row>
              </>
            ) : (
              <Row
                className="py-6 my-8"
                justify="center"
                style={{
                  border: `1px solid ${hexToRGBA(colorTheme, 0.3)}`,
                }}
              >
                <Col>
                  <Space direction="vertical" align="center">
                    <h5>You don’t have plan yet</h5>
                    <Button
                      type="primary"
                      title="Buy Plan"
                      onClick={() => history.push("/buy-plan")}
                      style={{
                        borderRadius: 0,
                        backgroundColor: colorTheme,
                      }}
                    />
                  </Space>
                </Col>
              </Row>
            )}

            <div className="d-flex justify-content-between gap-3 overflow-auto mb-2">
              <div className="d-flex">
                {[
                  { status: "Upcoming", value: "upcoming" },
                  { status: "Registered", value: "registered" },
                  // { status: "Wait List", value: "waiting list" },
                  // { status: "Pending", value: "pending" },
                  { status: "Cancelled", value: "cancelled" },
                ].map((item, index) => (
                  <div
                    onClick={() => {
                      setStatus(item?.value);
                    }}
                    key={index}
                    style={{
                      width: "max-content",
                      backgroundColor:
                        status === item?.value ? colorTheme : "#ffffff",
                      border: `1px solid ${hexToRGBA(colorTheme, 0.3)}`,
                    }}
                    className={`${
                      status === item?.value
                        ? `text-white`
                        : "pointer text-[#BDBDBD]"
                    } py-2 px-3 heading3`}
                  >
                    <b className="m-0 text-sm">{item?.status}</b>
                  </div>
                ))}
              </div>
              <div
                onClick={() => setStatus("past")}
                style={{
                  backgroundColor: status === "past" ? colorTheme : "#ffffff",
                  border: `1px solid ${hexToRGBA(colorTheme, 0.3)}`,
                }}
                className={`${
                  status === "past" ? `text-white` : "pointer text-[#BDBDBD]"
                } py-2 px-3 heading3`}
              >
                <b className="m-0 text-sm">Past</b>
              </div>
            </div>

            {classes?.data?.length > 0 ? (
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBorderRadius: 0,
                      headerBg:
                        hexToRGBA(studio_detail?.styles?.header_color, 0.1) ||
                        "#fafafa",
                    },
                  },
                }}
              >
                <Table
                  columns={columns}
                  size="middle"
                  dataSource={classes?.data}
                />
              </ConfigProvider>
            ) : (
              <Row justify="center" className="mt-16">
                <Col>
                  <h5>You don’t have any classes</h5>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>

      <Modal
        footer={null}
        closable={false}
        open={action.isOpenDetail}
        onCancel={() => setAction((prev) => ({ ...prev, isOpenDetail: false }))}
      >
        <div className="flex justify-between p-4 items-start">
          <Space direction="vertical">
            <span className="font-semibold text-[20px]">
              {class_detail.class_name}
            </span>
            {class_detail.is_online ? (
              <Tag color="#27AE60">Online</Tag>
            ) : (
              <Tag color="#2D9CDB">Onsite</Tag>
            )}
          </Space>

          <Tag color={color} className="capitalize">
            {action.selectedClass?.status}
          </Tag>
        </div>

        <div className="p-4 text-[#475569]">
          <div className="">
            <label className="font-bold">About the class</label>
            <p className="whitespace-pre-wrap">
              {class_detail.about_the_class}
            </p>
          </div>

          <div className="flex items-center">
            <label className="font-bold w-[40%]">Date & Time</label>
            <span>
              {class_detail.class_start_date}{" "}
              {action.selectedClass?.class_start_time}
            </span>
          </div>

          <div className="flex  items-center">
            <label className="font-bold w-[40%]">Teacher</label>
            <span className="font-semibold" style={{ color: colorTheme }}>
              {class_detail.teachers}
            </span>
          </div>

          {class_detail.is_online ? (
            <>
              <div className="flex  items-center">
                <label className="font-bold w-[40%]">Class link</label>
                <span>{class_detail.slots} ongoing</span>
              </div>

              <div className="flex  items-center">
                <label className="font-bold w-[40%]">
                  Additional information
                </label>
                <span>{class_detail.slots} ongoing</span>
              </div>
            </>
          ) : (
            <>
              <>
                <div className="flex  items-center">
                  <label className="font-bold w-[40%]">Location</label>
                  {class_detail?.locations?.length > 0 ? (
                    <span>
                      {class_detail?.locations.map((el) => el.name).join(", ")}
                    </span>
                  ) : (
                    <>-</>
                  )}
                </div>

                <div className="flex  items-center">
                  <label className="font-bold w-[40%]">Room</label>
                  <span>{class_detail.room}</span>
                </div>
              </>
            </>
          )}

          <div className="flex  items-center">
            <label className="font-bold w-[40%]">Slots</label>
            <span>{class_detail.slots} ongoing</span>
          </div>

          <div className="flex items-center mb-8">
            <label className="font-bold w-[40%]">Notes</label>
            <Space>
              <CheckOutlined style={{ color: colorTheme }} />
              <span>{class_detail.notes}</span>
            </Space>
          </div>

          {status === "pending" ? (
            <Row justify="center">
              <Col>
                <CustomButton
                  type="primary"
                  title="Confirm Payment"
                  style={{ width: 200 }}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      </Modal>

      <Modal
        footer={null}
        closable={false}
        open={action.isCancelClass}
        onCancel={() =>
          setAction((prev) => ({ ...prev, isCancelClass: false }))
        }
      >
        <h5 className="text-center">Class Cancel Request</h5>

        <div className="text-[#475569] p-4">
          <h6 className="font-bold">{class_detail.class_name}</h6>

          <div className="flex items-center">
            <label className="font-bold w-[40%]">Date & Time</label>
            <span>
              {class_detail.class_start_date}{" "}
              {action.selectedClass?.class_start_time}
            </span>
          </div>

          <div className="flex  items-center">
            <label className="font-bold w-[40%]">Teacher</label>
            <span className="font-semibold" style={{ color: colorTheme }}>
              {class_detail.teachers}
            </span>
          </div>

          <div className="my-4">
            <label className="font-bold">Email</label>
            <p>{profile.email}</p>
          </div>

          <label className="font-bold mb-2">Your messages</label>
          <Input.TextArea
            rows={4}
            placeholder="Type your text here.."
            onChange={(e) =>
              setAction((prev) => ({ ...prev, notes: e.target.value }))
            }
          />

          <div className="flex gap-2 my-4">
            <Checkbox
              onChange={(e) =>
                setAction((prev) => ({ ...prev, isChecked: e.target.checked }))
              }
            />
            <span>
              I’ve read the{" "}
              <u
                className="custom-hover pointer"
                onClick={() =>
                  setAction((prev) => ({
                    ...prev,
                    showPolicy: true,
                  }))
                }
              >
                cancellation policy
              </u>
            </span>
          </div>

          <Row justify="center">
            <Col>
              <Space direction="vertical">
                <Button
                  isLoading={!action.isChecked}
                  type="primary"
                  title="Send"
                  onClick={() => handleSubmit()}
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#EF4136",
                  }}
                />

                <Button
                  type="secondary"
                  title="Cancel"
                  onClick={() =>
                    setAction((prev) => ({ ...prev, isCancelClass: false }))
                  }
                  style={{
                    border: 0,
                    borderRadius: 0,
                    backgroundColor: "#fff",
                    color: colorTheme,
                  }}
                />
              </Space>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        footer={false}
        closable={false}
        open={action.showPolicy}
        onCancel={() => setAction((prev) => ({ ...prev, showPolicy: false }))}
      >
        <div className="p-4">
          <h5 className="font-bold text-center">
            {cancellation_setting?.cancellation_policy_title}
          </h5>
          <p className="overflow-auto" style={{ height: "20rem" }}>
            {cancellation_setting?.cancellation_policy_description}
          </p>
          <div className="flex justify-center mt-4">
            <CustomButton
              onClick={() =>
                setAction((prev) => ({ ...prev, showPolicy: false }))
              }
              title="Close"
              type="primary"
            />
          </div>
        </div>
      </Modal>

      <Modal
        footer={false}
        closable={false}
        open={action.cancelClassSubmitted}
        onCancel={() =>
          setAction((prev) => ({ ...prev, cancelClassSubmitted: false }))
        }
      >
        <div className="text-center">
          <h5 className="font-bold">Your request will be processed</h5>

          <p className="text-[#475569]">
            Please check your email for further information.
            <br />
            Thank you.
          </p>
        </div>

        <div className="flex justify-center mt-4">
          <CustomButton
            onClick={() =>
              setAction((prev) => ({ ...prev, showPolicy: false }))
            }
            title="Close"
            type="primary"
          />
        </div>
      </Modal>

      <Modal
        footer={false}
        open={selectedDate?.visible}
        onCancel={() => setSelectedDate({ visible: false })}
      >
        <div className="py-2 text-center">
          <DateRange
            className="shadow-sm mb-3"
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={() => setSelectedDate({ visible: false })}
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal centered show={welcomeModal} onHide={() => setWelcomeModal(false)}>
        <WellcomeOwner onClick={() => setWelcomeModal(false)} />
      </Modal>
    </Sidebar>
  );
};

export default StudentDashBoard;
