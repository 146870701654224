import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import google from "./../../assets/images/google_icon.png";
import facebook from "./../../assets/images/facebook_icon.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Dots from "../../assets/images/dots.svg";
import { Form } from "react-bootstrap";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { ModalLogin } from "./modal";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";
import { getStudioInfo } from "../../services/studios";

import {
  requestOtp,
  setStatusVerifyDefault,
  requestOtpActivate,
  setNewUser,
  setLoginFromStudio,
  setStatusDefault,
} from "../../redux/user/user.action";
import * as authService from "../../services/auth";

const StudioLogin = () => {
  const subdomain = window.location.host.split(".")[0];

  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, message, statusVerify, login_data, studio_detail } =
    useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState("");
  const [colorTheme, setColorTheme] = useState("");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalReactivateStudent, setShowModalReactivateStudent] =
    useState(false);
  const [showModalReactivateTeacher, setShowModalReactivateTeacher] =
    useState(false);
  const [registerWithSocmed, setRegisterWithSocmed] = useState(false);
  const [method, setMethod] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const responseSocialAuth = async (provider, response) => {
    try {
      console.log(response, "response");

      const access_token = response?.accessToken;
      setMethod(provider);
      setAccessToken(access_token);
      setEmail(response.profileObj?.email ?? response?.email);

      if (access_token) {
        const result = await authService.loginWithSocmed(
          provider,
          access_token
        );
        console.log("result", result);
        if (result.data.data.token) {
          dispatch(setLoginFromStudio());
          dispatch(setNewUser(result.data?.data));
          history.push("/dashboard");
        }
      }
    } catch (error) {
      if (error.response.data.message === "User not found") {
        setShowModalRegister(true);
        setRegisterWithSocmed(true);
      }
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleRegisterWithSocmed = async () => {
    try {
      const result = await authService.registerWithSocmed(method, accessToken);
      console.log(result, "result");
      if (result.data.data.token) {
        dispatch(setLoginFromStudio());
        dispatch(setNewUser(result.data?.data));
        history.push("/dashboard");
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
      dispatch(requestOtp(email));
    } else {
      dispatch(requestOtp(email, subdomain));
    }
  };

  const handleClose = () => {
    setShowModalRegister(false);
  };

  const redirectToRegister = () => {
    registerWithSocmed
      ? handleRegisterWithSocmed()
      : history.push("/register-student");
  };

  const sendRequestOtpActivate = () => {
    let params = {
      email: email,
      role: login_data?.role,
    };

    dispatch(requestOtpActivate(params, subdomain));
    if (login_data?.role === "teacher") {
      setShowModalReactivateTeacher(false);
      setEmail("");
    }
    login_data?.role === "student" &&
      history.push({ pathname: "/verify", state: { isReactivate: true } });
  };

  const handleGetStudioInfo = async () => {
    try {
      const res = await getStudioInfo(subdomain);
      setLogo(res.data.data.studio_logo_link);
      setColorTheme(res.data.data.studio_header_color);
    } catch (error) {
      console.log(error.config);
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    if (message === "User not registered") {
      setShowModalRegister(true);
      dispatch(setStatusVerifyDefault());
    }
  }, [dispatch, message]);

  useEffect(() => {
    if (login_data?.is_account_active === 0 && login_data?.role === "student") {
      setShowModalReactivateStudent(true);
    } else if (
      login_data?.is_account_active === 0 &&
      login_data?.role === "teacher"
    ) {
      setShowModalReactivateTeacher(true);
    }
  }, [login_data]);

  useEffect(() => {
    if (statusVerify === "success" && login_data?.is_account_active === 1) {
      dispatch(setStatusDefault());
      history.push("/verify");
    }
  }, [dispatch, history, login_data?.is_account_active, statusVerify]);

  useEffect(() => {
    handleGetStudioInfo();
  }, []);

  return (
    <div className="h-full">
      <div className="md:flex min-h-screen w-full">
        <img
          src={Dots}
          className="w-56 absolute top-[230px] -left-[100px] z-0 md:top-[75%]"
          alt="dots"
        />
        <img
          src={Dots}
          className="w-56 absolute top-0 right-0 md:right-[40%] z-0 "
          alt="dots"
        />
        <div
          className="md:w-3/5"
          style={{
            backgroundColor:
              colorTheme || studio_detail?.styles?.header_color || "#60369B",
            padding: 30,
          }}
        >
          <div className="text-white text-title">
            {studio_detail?.studio_name}
          </div>
          <div
            style={{ height: "100%" }}
            className="d-flex flex-column justify-content-center align-items-center flex-wrap text-white"
          >
            <img
              src={studio_detail?.logo_link || logo}
              alt="logo"
              className="logo-style"
            />
            <h4 className="mt-4">WELCOME TO GIGSCLASS</h4>
            <div style={{ width: 400, textAlign: "center", color: "#BAC2DB" }}>
              <p>
                One app fit for the entire studios. Making learn fun and
                exciting
              </p>
              <p>Flexible learning inspired by the pandemic</p>
            </div>
          </div>
        </div>
        <div className="flex  justify-center md:w-2/5  px-20 items-center">
          <div className="login-content ">
            <h1 className=" mt-4 mb-2   text-3xl">Sign in</h1>
            <div className="text-xs  my-5">
              If you don’t have an account register <br /> You can{" "}
              <span
                style={{
                  color: colorTheme || studio_detail?.styles?.header_color,
                }}
                className=" font-medium cursor-pointer hover:opacity-50"
                onClick={() => history.push("/register-student")}
              >
                Register here !
              </span>
            </div>
            <Form className="w-100" onSubmit={handleClick}>
              <TextInput
                required
                autoFocus
                label="Email"
                placeholder="johndoe@gmail.com"
                onChange={handleChange}
                value={email}
              />
              <Button
                buttonType="submit"
                title="CONTINUE"
                type="primary"
                className="mt-5"
                style={{
                  width: "100%",
                  backgroundColor:
                    colorTheme ||
                    studio_detail?.styles?.header_color ||
                    "#E26851",
                  borderRadius: 0,
                }}
                isLoading={isLoading}
              />
            </Form>
            <div className="w-full flex flex-col justify-center mb-24">
              <span className="custom-form-label my-2 text-center text-[#B5B5B5]">
                or continue with
              </span>
              <div className="flex items-center justify-center">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => {
                    return (
                      <img
                        alt="logo"
                        onClick={renderProps.onClick}
                        style={{
                          height: "25px",
                          width: "25px",
                          marginRight: "10px",
                        }}
                        src={google}
                        className="cursor-pointer"
                      />
                    );
                  }}
                  onSuccess={(res) => responseSocialAuth("google", res)}
                  onFailure={(res) => responseSocialAuth("google", res)}
                />
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  size="small"
                  render={(renderProps) => (
                    <img
                      className="cursor-pointer"
                      onClick={renderProps.onClick}
                      style={{
                        height: "25px",
                        width: "25px",
                        marginRight: "10px",
                      }}
                      src={facebook}
                      alt="logo"
                    />
                  )}
                  fields="name,email,picture"
                  callback={(res) => responseSocialAuth("facebook", res)}
                  cssClass="facebook-btn mt-3 custom-hover"
                />
              </div>
              <span className="mt-3 regular-text">
                By continuing, you agree to{" "}
                <a
                  target="_blank"
                  href="/general-terms"
                  className={`font-semibold no-underline text-[${
                    colorTheme ||
                    studio_detail?.styles?.header_color ||
                    "#60369B"
                  }]`}
                >
                  {studio_detail?.studio_name} General Terms
                </a>
              </span>
            </div>

            <div className="w-full flex justify-center">
              <span
                onClick={() => history.push("/public-class-list")}
                style={{
                  color: colorTheme || studio_detail?.styles?.header_color,
                }}
                className="text-center cursor-pointer hover:opacity-50 font-semibold"
              >
                Browse Class
              </span>
            </div>
          </div>
        </div>
      </div>

      <ModalLogin
        show={showModalRegister}
        title="Email Not Registered"
        label="Continue register with"
        email={email}
        titleButton1={"CHANGE"}
        titleButton2={"CONFIRM"}
        handleClose={handleClose}
        handleSubmit={redirectToRegister}
      />

      <ModalLogin
        show={showModalReactivateStudent}
        title="Welcome Back!"
        label="Continue to reactivate your account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={sendRequestOtpActivate}
      />

      <ModalLogin
        show={showModalReactivateTeacher}
        title="Your Account Is Currently Being Deactivated"
        label="Please contact the studio owner to re-adding your teacher account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"SEND EMAIL"}
        handleClose={() => setShowModalReactivateTeacher(false)}
        handleSubmit={sendRequestOtpActivate}
      />
    </div>
  );
};

export default StudioLogin;
