import React, { useEffect, useState } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import calender from "../../../../assets/images/calender.svg";
import AtomLoading from "./../../../../components/loding-indicator";
import {
  ConfigProvider,
  Table,
  Modal,
  Tag,
  Space,
  Divider,
  Radio,
  Select,
} from "antd";
import "./../students.styles.scss";
import { useCookies } from "react-cookie";
import {
  getListOfferingClasses,
  getMyActivePlan,
  activePlan,
} from "./../../../../redux/student/student.action";
import "react-calendar/dist/Calendar.css";
import EmptyData from "../../../../components/no-data";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { DateRange } from "react-date-range";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";
import { CheckOutlined } from "@ant-design/icons";
import { getStudentPlan } from "../../../../redux/owner/owner.action";
import { getLocation } from "../../../../redux/location/location.action";

const typeBoolean = {
  TRUE: true,
  FALSE: false,
};

const ClassIndex = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentDate = new Date();
  let last = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [cookies, setCookies] = useCookies(["user"]);
  const [isModalOpenDateRange, setIsModalDatOpeRange] = useState(false);
  const [isModalOpenDetailClass, setIsModalOpenDetailClass] = useState(false);
  const [prosessEndroll, setProsessEndroll] = useState(false);
  const [confirmMethod, setConfirmMethod] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [selectedClassDetail, setSelectedClassDetail] = useState({});
  const [dateRange, setDateRange] = useState([
    {
      startDate: currentDate,
      endDate: last,
      key: "selection",
    },
  ]);
  const [locationId, setLocationId] = useState([]);

  const [propsPayment, setPropsPayment] = useState();
  const [openStripe, setOpenStripe] = useState(typeBoolean.FALSE);

  const { payment_detail, student_plan } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { public_classes, class_list, isLoadingClasses, active_plan, status } =
    useSelector((state) => state.student);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const { location } = useSelector((state) => state.location);

  const colorTheme = studio_detail?.styles?.header_color;
  const student_id = props.location.state?.student_id;
  const fromOwner = props.location.state?.fromOwner;
  const isBrowseClasses = props.location.state?.isBrowseClasses;
  const class_offerings = isBrowseClasses ? public_classes : class_list;
  const subdomain = window.location.host.split(".")[0];

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const activePlanData = fromOwner ? student_plan?.data : active_plan;

  const redirectToLogin = () => {
    history.push("/login");
    toast.info("Please Register/Login to Continue.");
  };

  useEffect(() => {
    if (fromOwner) {
      dispatch(
        getListOfferingClasses(
          user_token,
          studio_id,
          `${moment(dateRange[0].startDate).format(
            "YYYY-MM-DD"
          )}&end_date=${moment(dateRange[0].endDate).format(
            "YYYY-MM-DD"
          )}&student_id=${student_id}`
        )
      );
      dispatch(
        getStudentPlan(user_token, studio_id, student_id, "status=active")
      );
    } else {
      dispatch(
        getListOfferingClasses(
          user_token,
          studio_id,
          `${moment(dateRange[0].startDate).format(
            "YYYY-MM-DD"
          )}&end_date=${moment(dateRange[0].endDate).format("YYYY-MM-DD")}`
        )
      );
      dispatch(getMyActivePlan(user_token, studio_id));
    }
  }, [
    dispatch,
    fromOwner,
    dateRange,
    student_id,
    studio_id,
    user_token,
    selectedDate,
  ]);

  useEffect(() => {
    dispatch(getLocation("?limit=0", user_token, studio_id));
  }, []);

  useEffect(() => {
    let url = propsPayment?.stripe_checkout_session_url;
    if (url !== undefined) {
      window.location.href = url;
    }
  }, [propsPayment?.stripe_checkout_session_url]);

  const showModalDateRange = () => {
    setIsModalDatOpeRange(true);
  };

  const handleOkDateRange = () => {
    setIsModalDatOpeRange(false);
  };

  const handleCancelDateRange = () => {
    setIsModalDatOpeRange(false);
  };

  const showModalDetailClass = () => {
    setIsModalOpenDetailClass(true);
  };

  const handleOkDetailClass = () => {
    setIsModalOpenDetailClass(false);
    setProsessEndroll(false);
  };

  const handleCancelDetailClass = () => {
    setIsModalOpenDetailClass(false);
    setProsessEndroll(false);
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const columns = [
    {
      title: "Class Name",
      dataIndex: "class_name",
      key: "class_name",
      render: (text, record) => {
        return <p className="font-black">{text}</p>;
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacher",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return text ? (
          <Tag color="#27AE60">Online</Tag>
        ) : (
          <Tag color="#2D9CDB">Onsite</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        if (record.user_has_booked_class) {
          return (
            <div
              style={{ color: colorTheme }}
              className="button flex justify-center items-center"
            >
              Register
            </div>
          );
        }
        return (
          <Button
            onClick={() => {
              setSelectedClassDetail(record);
              showModalDetailClass();
              setProsessEndroll(true);
            }}
            type="primary"
            title="Enroll Now"
            style={{
              backgroundColor: colorTheme || "#E26851",
              borderRadius: 0,
            }}
          />
        );
      },
    },
  ];

  const onChangeRadio = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleStripe = (params) => {
    setPropsPayment(params);
    setOpenStripe(typeBoolean.TRUE);
  };

  const handlePayment = async () => {
    const getPaymentDetails = (method) => {
      switch (method) {
        case "cash":
          return { payment_method: "offline", payment_type: "cash" };
        case "card":
          return { payment_method: "online", payment_type: "card" };
        default:
          return {
            payment_method: "active plan",
            payment_type: "active plan",
            plan_order_id: method,
          };
      }
    };

    const paymentDetails = getPaymentDetails(paymentMethod);

    const params = {
      category: "class",
      class_schedule_id: selectedClassDetail?.class_schedule_id,
      ...paymentDetails,
      location_id: locationId.join(","),
    };
    if (fromOwner) params.student_id = student_id;

    if (paymentMethod === "card") params.success_url = window.location.href;

    const { data } = await activePlan(user_token, params, studio_id);
    setPropsPayment(data?.data);

    setIsModalOpenDetailClass(false);
    setSelectedClassDetail({});
    setProsessEndroll(false);
    setPaymentMethod(null);
    setConfirmMethod(false);
  };

  return (
    <OrganismLayout isBrowseClasses={isBrowseClasses} maxWidth="42rem">
      {isLoadingClasses ? (
        <AtomLoading />
      ) : (
        <div className="px-8 md:px-2">
          <div className="flex justify-between">
            <h2 className="text-zinc-800 font-bold">Class Offerings</h2>
            <div className="d-flex align-items-center">
              <img alt="" src={calender} width={18} />
              <span
                onClick={showModalDateRange}
                className=" pointer custom-hover ms-2"
              >
                {` ${moment(dateRange[0].startDate).format(
                  "DD MMM, YYYY"
                )} - ${moment(dateRange[0].endDate).format("DD MMM, YYYY")}`}
              </span>
            </div>
          </div>

          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBorderRadius: 0,
                  headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
                },
              },
            }}
          >
            <div className="gap-6">
              {class_list?.length > 0 ? (
                class_list.map((data, idx) => {
                  const dataClass = data?.class_schedules.map((el, i) => {
                    return {
                      key: i,
                      class_name: el.class_name,
                      time: el.class_start_time + " - " + el.class_end_time,
                      teacher: el.teachers.join(),
                      type: el.is_online,
                      ...el,
                    };
                  });
                  return (
                    <div key={idx} className="py-9">
                      <div
                        style={{
                          borderColor: colorTheme,
                        }}
                        className="border border-opacity-30"
                      >
                        <div className="px-2 py-4 flex justify-between items-center font-semibold">
                          <div>{days[moment(data?.class_date).day()]}</div>
                          <div>
                            {moment(data?.class_date).format("DD MMMM, YYYY")}
                          </div>
                        </div>

                        <Table
                          className="min-w-100"
                          columns={columns}
                          size="middle"
                          dataSource={dataClass}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyData />
              )}
            </div>
          </ConfigProvider>
        </div>
      )}

      <Modal
        footer={null}
        open={isModalOpenDateRange}
        onOk={handleOkDateRange}
        onCancel={handleCancelDateRange}
      >
        <DateRange
          className="w-full"
          // editableDateInputs={true}
          onChange={(item) => setDateRange([item.selection])}
          // moveRangeOnFirstSelection={false}
          ranges={dateRange}
        />
      </Modal>

      <Modal
        open={isModalOpenDetailClass}
        onOk={handleOkDetailClass}
        onCancel={handleCancelDetailClass}
        footer={null}
        width={700}
      >
        {prosessEndroll && (
          <div className="w-full text-center text-2xl font-semibold">
            Payment
          </div>
        )}

        <div className="flex  justify-between p-4 items-center">
          <span className="font-semibold text-[20px]">
            {selectedClassDetail.class_name}
          </span>
          {selectedClassDetail.is_online ? (
            <Tag color="#27AE60">Online</Tag>
          ) : (
            <Tag color="#2D9CDB">Onsite</Tag>
          )}
        </div>

        <div className="p-4 gap-8 text-[#475569]">
          <div className="flex  items-center">
            <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
              Schedule
            </label>
            <span>
              {moment(selectedClassDetail.class_date).format("LL")}{" "}
              {selectedClassDetail.time}
            </span>
          </div>
          <div className="flex  items-center">
            <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
              Teacher
            </label>
            <span className="font-semibold">{selectedClassDetail.teacher}</span>
          </div>
          <div className="flex  items-center">
            <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
              Slots
            </label>
            <span>{selectedClassDetail.slots} Remaining</span>
          </div>
          <div className="flex gap-4">
            <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
              Description
            </label>
            <p className="whitespace-pre-wrap">
              {selectedClassDetail.about_the_class}
            </p>
          </div>
          <div className="flex  items-center">
            <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
              What You’ll Need
            </label>
            <Space>
              <CheckOutlined />
              <span>{selectedClassDetail.notes}</span>
            </Space>
          </div>

          <Divider style={{ borderColor: "#E0E0E0" }} />

          <div className="grid gap-2">
            <label
              htmlFor="location"
              className="text-slate-900 text-sm font-bold"
            >
              Location
            </label>

            <div
              className={`py-2 px-4`}
              style={{
                backgroundColor: hexToRGBA(colorTheme || "#E26851", 0.1),
                border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
              }}
            >
              <span className="font-medium">
                Select a location to set as your timezone reference before
                enrolling.
              </span>
            </div>

            <Select
              size="large"
              mode="multiple"
              placeholder="Jakarta"
              options={location?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              value={locationId}
              onChange={(e) => {
                setLocationId(e);
              }}
              className="w-full rounded-sm"
            />
          </div>

          <Divider style={{ borderColor: "#E0E0E0" }} />

          {!confirmMethod && activePlanData?.length > 0 ? (
            <Space direction="vertical">
              <b>How do you pay for it?</b>

              <Radio.Group onChange={onChangeRadio} value={paymentMethod}>
                <Space direction="vertical">
                  {activePlanData?.length > 0 &&
                    activePlanData.map((el) => (
                      <Radio value={el?._id ?? el.plan_transaction_id}>
                        {el?.plan?.plan_name ?? el?.plan_name}
                      </Radio>
                    ))}
                  {selectedClassDetail?.direct_payment_allowed ? (
                    <Radio value={fromOwner ? "cash" : "card"}>
                      Buy this class for
                      <b>
                        {" "}
                        {selectedClassDetail.total_amount}{" "}
                        {selectedClassDetail.currency?.toUpperCase()}
                      </b>
                    </Radio>
                  ) : (
                    <></>
                  )}
                </Space>
              </Radio.Group>
            </Space>
          ) : (
            <>
              <div className="text-center text-base font-bold text-gray-600">
                This session for
                {paymentMethod === "cash" || paymentMethod === "card"
                  ? ` ${
                      selectedClassDetail.total_amount
                    } ${selectedClassDetail.currency?.toUpperCase()}`
                  : ` ${selectedClassDetail.credits} credits`}
                <br />
                <br />
                {activePlanData?.length === 0 &&
                !selectedClassDetail?.direct_payment_allowed ? (
                  <Button
                    type="primary"
                    title="Buy a plan"
                    onClick={() => {
                      history.push({
                        pathname: "/admin/buy-plan",
                        state: { student_id: student_id, fromOwner: true },
                      });
                    }}
                    style={{
                      backgroundColor: colorTheme || "#E26851",
                      borderRadius: 0,
                    }}
                  />
                ) : null}
              </div>
            </>
          )}

          {!prosessEndroll && (
            <div className="flex w-full justify-center">
              <Button
                type="primary"
                title="Enroll Now"
                style={{
                  backgroundColor: colorTheme || "#E26851",
                  borderRadius: 0,
                  marginTop: 24,
                }}
                onClick={() => {
                  setProsessEndroll(true);
                }}
              />
            </div>
          )}

          {prosessEndroll && paymentMethod ? (
            <div className="flex flex-col w-full justify-center items-center">
              {activePlanData?.length > 0 ||
              selectedClassDetail?.direct_payment_allowed ? (
                <Button
                  isLoading={locationId.length === 0}
                  type="primary"
                  title={confirmMethod ? "Enroll Now" : "Confirm"}
                  style={{
                    backgroundColor: colorTheme || "#E26851",
                    borderRadius: 0,
                    marginTop: 24,
                    width: "200px",
                  }}
                  onClick={() => {
                    if (confirmMethod) {
                      handlePayment();
                    } else {
                      setConfirmMethod(true);
                    }
                  }}
                />
              ) : (
                <></>
              )}

              <Button
                buttonType="button"
                type="secondary"
                title="Cancel"
                onClick={() => {
                  setIsModalOpenDetailClass(false);
                  setSelectedClassDetail({});
                  setProsessEndroll(false);
                  setPaymentMethod(null);
                  setConfirmMethod(false);
                }}
                style={{
                  backgroundColor: "#fff",
                  color: colorTheme,
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </OrganismLayout>
  );
};

export default ClassIndex;
