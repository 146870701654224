import React, { useEffect, useMemo, useRef, useState } from "react";

import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./../students.styles.scss";
import { Col } from "react-bootstrap";
import AtomLoading from "./../../../../components/loding-indicator";
import AtomNumberFormat from "./../../../../components/number-format";

import { getListOfferingPlans } from "../../../../redux/student/student.action";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import EmptyData from "../../../../components/no-data";
import { toast } from "react-toastify";
import { sendGetRequest, sendPostRequest } from "../../../../services/students";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import hexToRGBA from "../../../../helper/hexToRbga";

const BuyPlans = (props) => {
  const slider = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies(["user"]);
  const [plans, setPlans] = useState([]);
  const [propsPayment, setPropsPayment] = useState();

  const student_id = props.location.state?.student_id;
  const fromOwner = props.location.state?.fromOwner;

  const { payment_detail } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, plan_list } = useSelector((state) => state.student);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const planData = fromOwner ? plans : plan_list;

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const plans_type_class_pass = planData?.filter(
    (item) => item?.plan_type_group === "class pass"
  );
  const plans_type_membership = planData?.filter(
    (item) => item?.plan_type_group === "membership"
  );
  const themeColor = studio_detail?.styles?.header_color;
  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: mobileSize ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  const CustomPrevButton = (props) => {
    const { className, style, onClick } = props;
    return (
      <div>
        <FaAngleLeft
          style={{
            ...style,
            borderRadius: "50%",
            width: 25,
            height: 25,
          }}
          onClick={onClick}
          className={`${className} greyblue bg-white shadow-sm custom-hover`}
        />
      </div>
    );
  };

  const CustomNextButton = (props) => {
    const { className, style, onClick } = props;
    return (
      <div>
        <FaAngleRight
          style={{
            ...style,
            borderRadius: "50%",
            width: 25,
            height: 25,
          }}
          onClick={onClick}
          className={`${className} greyblue bg-white shadow-sm custom-hover`}
        />
      </div>
    );
  };

  useEffect(() => {
    let url = propsPayment?.stripe_checkout_session_url;
    if (url !== undefined) {
      window.location.href = url;
    }
  }, [propsPayment?.stripe_checkout_session_url]);

  const buyPlanOffline = async (plan_id) => {
    let params = {
      category: "plan",
      plan_id: plan_id,
      payment_method: "offline",
      payment_type: "cash",
      student_id: student_id,
    };

    try {
      await sendPostRequest(user_token, studio_id, "/orders", params);
      toast.success("Successfully Add Plan for Student!");
      // history.goBack();
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const buyPlan = async (plan_id) => {
    let params = {
      category: "plan",
      plan_id: plan_id,
      payment_method: "online",
      payment_type: "card",
      student_id: student_id,
      success_url: window.location.href,
    };

    try {
      const { data } = await sendPostRequest(
        user_token,
        studio_id,
        "/orders",
        params
      );
      toast.success("Successfully Add Plan for Student!");
      setPropsPayment(data?.data);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
    // history.push({
    //   pathname: "/choose-payment",
    //   state: { plan_id: plan_id },
    // });
  };

  console.log(propsPayment);

  const handleBuyPlan = (plan_id) => {
    if (fromOwner) {
      buyPlanOffline(plan_id);
    } else {
      buyPlan(plan_id);
    }
  };

  const getStudentPlans = async () => {
    const baseUrl = "/offering-plans";
    const url = fromOwner ? `${baseUrl}?student_id=${student_id}` : baseUrl;

    try {
      const res = await sendGetRequest(user_token, studio_id, url);
      setPlans(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fromOwner
      ? getStudentPlans()
      : dispatch(getListOfferingPlans(user_token, studio_id));
  }, [dispatch, fromOwner, studio_id, user_token]);

  return (
    <OrganismLayout>
      {isLoading ? (
        <AtomLoading />
      ) : (
        <Col className="text-center">
          <h2 className=" font-bold mb-5">Choose Plan</h2>
          {plans_type_class_pass?.length > 0 ? (
            <>
              <span className="font-bold text-gray-600">Based on Credits</span>
              <div className="mt-3 flex gap-4 max-w-screen-xl overflow-auto">
                {plans_type_class_pass[0]?.plan_data
                  .filter((data) => data.status_plan === "active")
                  .map((el, idx) => (
                    <div key={idx}>
                      <CardPlan
                        data={el}
                        themeColor={themeColor}
                        paymentDetail={payment_detail}
                        typeCard={"Credit"}
                        handleBuyPlan={handleBuyPlan}
                      />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <span>Based on Credits</span>
              <div className="py-5">
                <EmptyData />
              </div>
            </>
          )}

          {plans_type_membership?.length > 0 ? (
            <>
              <span className="font-bold text-gray-600">Based on Session</span>
              <div className="mt-3 flex gap-4 max-w-screen overflow-auto">
                {plans_type_membership[0]?.plan_data
                  .filter((data) => data.status_plan === "active")
                  .map((el, idx) => (
                    <div key={idx}>
                      <CardPlan
                        data={el}
                        themeColor={themeColor}
                        paymentDetail={payment_detail}
                        typeCard={"Session"}
                        handleBuyPlan={handleBuyPlan}
                      />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <span>Based on Session</span>
              <div className="py-5">
                <EmptyData />
              </div>
            </>
          )}
        </Col>
      )}
    </OrganismLayout>
  );
};

const CardPlan = ({
  data,
  themeColor,
  payment_detail,
  typeCard,
  handleBuyPlan,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        minHeight: "20rem",
        border: `1px solid ${hexToRGBA(themeColor, 0.2)}`,
        backgroundColor: hover ? themeColor : "white",
      }}
      className={`Rectangle-15 p-3 mb-5 mx-auto d-flex flex-column justify-content-between  custom-hover5 `}
    >
      {typeCard === "Credit" ? (
        <>
          <h6>{data?.plan_name}</h6>
          <b>{data?.credits} Credits</b>
          <h5>
            <AtomNumberFormat prefix="IDR " value={data?.total_amount} />
          </h5>
          <p className="py-3 fs-12 overflow-hidden">{data?.notes}</p>
          <span className="fs-12">{data?.duration}</span> <br />
          {data?.user_has_active_plan ? (
            <span className="fs-10 text-danger mb-3">
              You already have this plan
            </span>
          ) : (
            <div className=""></div>
          )}
          <button
            onClick={() => handleBuyPlan(data?.plan_id)}
            style={{
              color: hover ? themeColor : "white",
              backgroundColor: hover ? "white" : themeColor,
              borderRadius: 0,
            }}
            disabled={
              (data?.user_has_active_plan &&
                data?.user_has_pending_payment === false) ||
              (data?.user_has_active_plan === false &&
                data?.user_has_pending_payment) ||
              (payment_detail?.bank_transfer === false &&
                payment_detail?.online_payment === false)
            }
            className={`${
              (data?.user_has_active_plan &&
                data?.user_has_pending_payment === false) ||
              (data?.user_has_active_plan === false &&
                data?.user_has_pending_payment)
                ? "disabled"
                : ""
            }  justify-content-center border-0 fw-bold  w-100 p-2`}
          >
            {data?.user_has_active_plan &&
            data?.user_has_pending_payment === false
              ? "REGISTERED"
              : data?.user_has_active_plan === false &&
                data?.user_has_pending_payment
              ? "PENDING"
              : payment_detail?.bank_transfer === false &&
                payment_detail?.online_payment === false
              ? "NOT AVAILABLE"
              : "BOOK NOW"}
          </button>
        </>
      ) : (
        <>
          <h6>{data?.plan_name}</h6>
          <b>{data?.session} Session</b>
          <h5>
            <AtomNumberFormat prefix="IDR " value={data?.total_amount} />
          </h5>
          <p className="py-3 fs-12 overflow-hidden">{data?.notes}</p>
          <span className="fs-12">{data?.duration}</span> <br />
          {data?.user_has_active_plan &&
          data?.user_has_pending_payment === false ? (
            <span className="fs-10 text-danger mb-3">
              You already have this plan
            </span>
          ) : (
            <div className=""></div>
          )}
          <button
            onClick={() => handleBuyPlan(data?.plan_id)}
            style={{
              color: hover ? themeColor : "white",
              backgroundColor: hover ? "white" : themeColor,
              borderRadius: 0,
            }}
            disabled={
              (data?.user_has_active_plan &&
                data?.user_has_pending_payment === false) ||
              (data?.user_has_active_plan === false &&
                data?.user_has_pending_payment) ||
              (payment_detail?.bank_transfer === false &&
                payment_detail?.online_payment === false)
            }
            className={`${
              (data?.user_has_active_plan &&
                data?.user_has_pending_payment === false) ||
              (data?.user_has_active_plan === false &&
                data?.user_has_pending_payment)
                ? "disabled"
                : ""
            }  justify-content-center border-0 fw-bold  w-100 p-2`}
          >
            {data?.user_has_active_plan &&
            data?.user_has_pending_payment === false
              ? "REGISTERED"
              : data?.user_has_active_plan === false &&
                data?.user_has_pending_payment
              ? "PENDING"
              : payment_detail?.bank_transfer === false &&
                payment_detail?.online_payment === false
              ? "NOT AVAILABLE"
              : "BOOK NOW"}
          </button>
        </>
      )}
    </div>
  );
};

export default BuyPlans;
