import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// import { Col, Form } from "react-bootstrap";

import Button from "../../../../components/Button/button.component";
import AtomLoading from "../../../../components/loding-indicator";
import { ConfigProvider, Form, Input, Select, Checkbox } from "antd";
import {
  addStudent,
  getStudentDetail,
  updateStudent,
  setStatusDefault,
} from "../../../../redux/owner/owner.action";
import { ModalLogin } from "../../../StudioLogin/modal";
import {
  activateAccountUser,
  setMenuActive,
} from "../../../../redux/user/user.action";
import UploadPicture from "../../../../components/uploadPicture";
import hexToRGBA from "../../../../helper/hexToRbga";

export const AddStudent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [cookies, setCookies] = useCookies(["user"]);
  const [pictureData, setPictureData] = useState(null);
  const [showModalReactivateStudent, setShowModalReactivateStudent] =
    useState(false);

  const {
    isLoadingStudent,
    addStudentStatus,
    updateStudentStatus,
    student_detail,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const id = props.id;
  const isCreating = window.location.pathname.includes("add") ? true : false;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  useEffect(() => {
    if (id) dispatch(getStudentDetail(user_token, studio_id, id));
    dispatch(setMenuActive("Student"));
  }, [id]);

  useEffect(() => {
    if (!isCreating) {
      form.setFieldsValue({
        first_name: student_detail?.first_name,
        last_name: student_detail?.last_name,
        email: student_detail?.email,
        phone_number: student_detail?.phone_number,
        gender: student_detail?.gender,
      });
      setPictureData(student_detail?.profile_picture);
    }
  }, [student_detail]);

  useEffect(() => {
    // if (add_student_res?.is_account_active === 0) {
    //   setShowModalReactivateStudent(true);
    //   return;
    // }
    if (addStudentStatus === "success") {
      props.setStep((prevStep) => prevStep + 1);
    } else if (updateStudentStatus === "success") {
      history.goBack();
      dispatch(setStatusDefault());
    }
  }, [addStudentStatus, updateStudentStatus]);

  useEffect(() => {
    console.log(student_detail);
  }, [student_detail]);

  const handleActivateAccount = () => {
    try {
      dispatch(
        activateAccountUser(user_token, studio_id, {
          email: student_detail?.email,
          role: "student",
        })
      );

      dispatch(setStatusDefault());
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = ({
    first_name,
    last_name,
    email,
    phone_number,
    send_welcome_email,
    gender,
  }) => {
    let params = {
      first_name,
      last_name,
      email,
      send_welcome_email: send_welcome_email ? 1 : 0,
      gender,
    };
    if (phone_number) params.phone_number = phone_number;
    if (pictureData) params.profile_picture = pictureData;

    console.log(params, isCreating);
    isCreating
      ? dispatch(addStudent(params, user_token, studio_id))
      : dispatch(updateStudent(params, user_token, studio_id, id));
  };

  return (
    <>
      {isLoadingStudent ? (
        <AtomLoading />
      ) : (
        <div>
          <ConfigProvider
            theme={{
              components: {},
            }}
          >
            <h2 className="font-bold mb-3">
              {isCreating ? "Add Student" : "Edit Student"}
            </h2>
            <Form
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
              className="w-full md:w-[568px]"
              scrollToFirstError
            >
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  { required: true, message: "Please input your First Name" },
                ]}
              >
                <Input className="h-10" placeholder="Input Your First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  { required: true, message: "Please input your Last Name" },
                ]}
              >
                <Input placeholder="Input Your Last Name" className="h-10" />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email Address",
                  },
                ]}
              >
                <Input placeholder="Input Your Email" className="h-10" />
              </Form.Item>
              <Form.Item label="Phone Number" name="phone_number">
                <Input placeholder="Input Your Phone Number" className="h-10" />
              </Form.Item>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Please select your Gender",
                  },
                ]}
                initialValue="male"
              >
                <Select
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                  placeholder="Select Your Gender"
                  className="h-10"
                />
              </Form.Item>

              <UploadPicture
                themeColor={colorTheme}
                valuePicture={pictureData}
                fileData={(file) => setPictureData(file)}
              />
              {isCreating && (
                <Form.Item valuePropName="checked" name="send_welcome_email">
                  <Checkbox placeholder="Input Your Phone Number">
                    Send welcome email
                  </Checkbox>
                </Form.Item>
              )}
              <Form.Item>
                <div className="flex justify-between">
                  <Button
                    buttonType="button"
                    type="secondary"
                    title="Cancel"
                    onClick={() => {
                      history.goBack();
                    }}
                    style={{
                      width: "25%",
                      backgroundColor: "#fff",
                      color: colorTheme,
                      borderRadius: 0,
                      border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
                    }}
                  />
                  <Button
                    buttonType="submit"
                    type="primary"
                    title={isCreating ? "Continue" : "Save"}
                    style={{
                      width: "25%",
                      backgroundColor: colorTheme,
                      borderRadius: 0,
                    }}
                    // isLoading={isLoadingStudent}
                  />
                </div>
              </Form.Item>
            </Form>
          </ConfigProvider>
          {/* <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Form.Group style={{ marginBottom: 10 }}>
              <TextInput
                required
                autoFocus
                label="First Name"
                placeholder="John"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: 10 }}>
              <TextInput
                label="Last Name"
                placeholder="Chena"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: 10 }}>
              <TextInput
                inputClassName={`${!isCreating && "border-0"}`}
                required={isCreating}
                readOnly={!isCreating}
                label="Email Address"
                placeholder="John@gmail.com"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: 10 }}>
              <TextInput
                max={13}
                type="number"
                label="Phone Number"
                placeholder="087878****"
                value={phoneNumber}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
              />
            </Form.Group>
            {isCreating && (
              <Form.Group>
                <Form.Check
                  id="sent"
                  type="checkbox"
                  label="Send welcome email"
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#000",
                  }}
                  onChange={(event) => {
                    setSendEmail(event.target.checked ? 1 : 0);
                  }}
                />
              </Form.Group>
            )}
            <div className="mt-3 mb-2 d-flex justify-content-center gap-3">
              <Button
                type={"outline-secondary"}
                title={isOnboarding ? "Skip for Now" : "Cancel"}
                onClick={() => {
                  if (isOnboarding) {
                    onboarding_status?.first_teacher === false
                      ? history.push({
                        pathname: "/add-teacher",
                        state: { isOnboarding: true },
                      })
                      : onboarding_status?.schedule_class === false
                        ? history.push({
                          pathname: "/add-class",
                          state: { isOnboarding: true },
                        })
                        : onboarding_status?.plan === false
                          ? history.push({
                            pathname: "/add-plan",
                            state: { isOnboarding: true },
                          })
                          : onboarding_status?.term_and_condition === false
                            ? history.push({
                              pathname: "/setting-terms-and-conditions",
                              state: { isOnboarding: true, step: 2 },
                            })
                            : onboarding_status?.cancellation_setting === false
                              ? history.push({
                                pathname: "/setting-cancellation",
                                state: { isOnboarding: true, step: 2 },
                              })
                              : onboarding_status?.payment_information === false
                                ? history.push({
                                  pathname: "/admin/setting-payment-gateway",
                                  state: { isOnboarding: true, step: 2 },
                                })
                                : history.goBack();
                  } else {
                    history.goBack();
                  }
                }}
              />
              <Button
                buttonType="submit"
                type="primary"
                title="CONTINUE"
                isLoading={isLoadingStudent}
              />
            </div>
          </Form> */}
        </div>
      )}

      <ModalLogin
        show={showModalReactivateStudent}
        title="The Account Is Currently Being Deactivated"
        label="Continue to reactivate the student account"
        email={student_detail?.email}
        titleButton1={"Cancel"}
        titleButton2={"REACTIVATE"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={handleActivateAccount}
      />
    </>
  );
};
