import axiosInstance from "../axiosInstance";

export const getBanksCode = async (token_jwt, studio_id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get("disbursements/banks", config);

  return res;
};

export const getPaymentCode = async (token_jwt, studio_id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get("oy/payment-type", config);

  return res;
};

export const getDisbursementSetting = async (token_jwt, studio_id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get("disbursements", config);

  return res;
};

export const payWithOy = async (token_jwt, studio_id, params) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post("orders", params, config);

  return res;
};

export const continuePayment = async (token_jwt, studio_id, order_id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(`orders/${order_id}`, config);
  return res;
};

export const connectToStripe = async (token_jwt, studio_id, return_url) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post(
    "stripe/connect",
    { return_url: return_url, refresh_url: return_url },
    config
  );

  return res;
};
