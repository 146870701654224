import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./../students.styles.scss";

import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import Footer from "../../../../components/Footer/footer.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import { useCookies } from "react-cookie";

import {
  verifyOtpChangeEmail,
  requestOtp,
  changeEmail,
  setStatusVerifyEmailDefault,
} from "../../../../redux/student/student.action";
import { setStatusDefault } from "../../../../redux/user/user.action";

const VerifyEmail = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [otp, setOtp] = useState("");
  const [remainTime, setRemainTime] = useState(60);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { email, statusVerifyEmail, isLoading } = useSelector(
    (state) => state.student,
  );
  const { user, status, loginFromStudio } = useSelector((state) => state.user);

  const verifyCurrentEmail = props.location.state?.verifyCurrentEmail;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      email: email,
      otp: Number(otp),
    };
    let newEmail = {
      new_email: email,
    };

    if (verifyCurrentEmail) {
      dispatch(verifyOtpChangeEmail(user_token, studio_id, params));
    } else {
      dispatch(verifyOtpChangeEmail(user_token, studio_id, params));
      dispatch(changeEmail(user_token, studio_id, newEmail));
    }
  };

  const resendCode = () => {
    dispatch(requestOtp(user_token, studio_id, email));
  };

  useEffect(() => {
    if (verifyCurrentEmail && statusVerifyEmail === "success") {
      history.push({
        pathname: "/change-email",
        state: email,
      });
      dispatch(setStatusVerifyEmailDefault());
    } else if (statusVerifyEmail === "success") {
      history.push("/student-profile");
    }
  });

  useEffect(() => {
    if (!remainTime) return;
    const timer = setInterval(() => {
      setRemainTime(remainTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [remainTime]);

  useEffect(() => {
    if (status === "success") {
      dispatch(setStatusDefault());
      setRemainTime(60);
    }
  }, [dispatch, status]);

  return (
    <Container fluid style={{ backgroundColor: "#f8f8f8" }}>
      <Container style={{ minHeight: "100vh" }}>
        <Row style={{ height: "100%" }}>
          <Col
            lg={4}
            className='mx-auto d-flex align-items-center justify-content-center flex-column p-5'
            style={{ height: "80%" }}
          >
            <h4 style={{ fontFamily: "Roboto", color: "#ce7499" }}>
              Verify Your Email Address
            </h4>
            <p className='regular-text text-center mb-5 mt-3'>
              We emailed you 6 (six) digits code to <b>{email}</b> Enter the
              code below to confirm your{" "}
              {verifyCurrentEmail ? "current" : "new"} email address
            </p>
            <Form onSubmit={handleSubmit}>
              <TextInput
                autoFocus
                required
                min={6}
                max={6}
                placeholder='000000'
                onChange={(event) => {
                  setOtp(event.target.value);
                }}
                style={{
                  fontSize: "24px",
                  fontFamily: "Roboto",
                  color: "#000",
                  textAlign: "center",
                  width: "50%",
                  margin: "0 auto",
                }}
                className='mt-3'
                value={otp}
                // onKeyUp={(e) => {
                //   if (e.code === "Enter") {
                //     handleSubmit();
                //   }
                // }}
              />
              <p className='regular-text text-center mb-3 mt-3'>
                The verification code above is only valid for 30 minutes. Please
                don’t share this code with anyone, including those on behalf of{" "}
                {user?.studio_name ?? cookies.user?.studio_name} or GigsClass
              </p>
              <p className='regular-text text-center'>
                Didn't receive the code? <br />
                {!remainTime ? (
                  <u className='pointer greyblue' onClick={resendCode}>
                    Resend Code
                  </u>
                ) : (
                  <span className='greyblue'>
                    Resend Code in {remainTime} Second.
                  </span>
                )}
              </p>
              <div className='d-flex justify-content-center'>
                <Button
                  type='primary'
                  title='VERIFY'
                  buttonType='submit'
                  isLoading={isLoading}
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default VerifyEmail;
