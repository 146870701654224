import React, { useEffect, useState } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { ConfigProvider, Table, Modal, Tag } from "antd";
import AtomLoading from "../../../../components/loding-indicator";
import calender from "../../../../assets/images/calender.svg";
import { DateRange } from "react-date-range";
import "./../students.styles.scss";
import { getPublicListOfferingClasses } from "../../../../redux/student/student.action";
import "react-calendar/dist/Calendar.css";
// import DatePicker from "../../../../components/date-picker";
import EmptyData from "../../../../components/no-data";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";

const PublicClassList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const classIds = window.location.search.split("=")[1];

  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [isModalOpenDateRange, setIsModalDatOpeRange] = useState(false);
  const [isModalOpenDetailClass, setIsModalOpenDetailClass] = useState(false);
  const [active, setActive] = useState("");
  const [show, setShow] = useState(false);
  const [selectedClassDetail, setSelectedClassDetail] = useState({});
  const [state, setState] = useState([
    {
      startDate: currentDate,
      endDate: moment(selectedDate).add(6, "d"),
      key: "selection",
    },
  ]);
  const { public_classes, isLoadingClasses } = useSelector(
    (state) => state.student
  );
  const { studio_detail } = useSelector((state) => state.user);
  const class_list = classIds
    ? public_classes
        .flatMap((el) => el.class_schedules)
        .filter((x) => x.class_id === classIds)
    : public_classes;

  const subdomain = window.location.host.split(".")[0];

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      active === eventKey ? setActive("") : setActive(eventKey)
    );

    return (
      <div style={{ cursor: "pointer" }} onClick={decoratedOnClick}>
        {children}
      </div>
    );
  };

  const redirectToLogin = () => {
    history.push("/login");
    toast.info("Please Register/Login to Continue.");
  };

  useEffect(() => {
    dispatch(
      getPublicListOfferingClasses(
        subdomain,
        moment(selectedDate).format("YYYY-MM-DD")
      )
    );
  }, [dispatch, selectedDate, subdomain]);

  useEffect(() => {
    console.log(class_list);
  }, [class_list]);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const columns = [
    {
      title: "Class Name",
      dataIndex: "class_name",
      key: "class_name",
      render: (text, record) => {
        return (
          <p
            className=" cursor-pointer"
            onClick={() => {
              setSelectedClassDetail(record);
              showModalDetailClass(true);
            }}
          >
            {text}
          </p>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacjer",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return text ? (
          <Tag color="#27AE60">Online</Tag>
        ) : (
          <Tag color="#2D9CDB">Onsite</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: () => (
        <Button
          onClick={redirectToLogin}
          type="primary"
          title="Enroll Now"
          style={{
            backgroundColor: studio_detail?.styles?.header_color || "#E26851",
            borderRadius: 0,
          }}
        />
      ),
    },
  ];

  const showModalDateRange = () => {
    setIsModalDatOpeRange(true);
  };

  const handleOkDateRange = () => {
    setIsModalDatOpeRange(false);
  };

  const handleCancelDateRange = () => {
    setIsModalDatOpeRange(false);
  };

  const showModalDetailClass = () => {
    setIsModalOpenDetailClass(true);
  };

  const handleOkDetailClass = () => {
    setIsModalOpenDetailClass(false);
  };

  const handleCancelDetailClass = () => {
    setIsModalOpenDetailClass(false);
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      console.log({
        selectedRowKeys: selectedRowKeys,
      });
    },
  };

  return (
    <OrganismLayout isBrowseClasses={true} title="Class List" maxWidth="42rem">
      {isLoadingClasses ? (
        <AtomLoading />
      ) : (
        <div className="px-8 md:px-[200px] py-10">
          <div className="flex justify-between">
            <h1 className="text-zinc-800 text-[40px] font-bold">
              Class Offerings
            </h1>
            <div className="d-flex align-items-center">
              <img alt="" src={calender} width={18} />
              <span
                onClick={showModalDateRange}
                className=" pointer custom-hover ms-2"
              >
                {`${moment(selectedDate).format("DD MMM, YYYY")} - ${moment(
                  selectedDate
                )
                  .add(6, "d")
                  .format("DD MMM, YYYY")}`}
              </span>
            </div>
          </div>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg:
                    hexToRGBA(studio_detail?.styles?.header_color, 0.1) ||
                    "#fafafa",
                  /* here is your component tokens */
                },
              },
            }}
          >
            <div className="gap-6">
              {class_list?.length > 0 ? (
                class_list.map((data, idx) => {
                  const dataClass = data?.class_schedules.map((el, i) => {
                    return {
                      key: i,
                      class_name: el.class_name,
                      time: el.class_start_time + " - " + el.class_end_time,
                      teacher: el.teachers.join(),
                      type: el.is_online,
                      ...el,
                    };
                  });
                  return (
                    <div key={idx} className="py-9">
                      <div
                        style={{
                          borderColor: studio_detail?.styles?.header_color,
                        }}
                        className="border border-opacity-30"
                      >
                        <div className="px-2 py-4 flex justify-between items-center font-semibold">
                          <div>{days[moment(data?.class_date).day()]}</div>
                          <div>{moment(data?.class_date).format("LL")}</div>
                        </div>
                        <Table
                          className="min-w-100"
                          columns={columns}
                          size="middle"
                          dataSource={dataClass}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyData />
              )}
            </div>
          </ConfigProvider>
        </div>
      )}
      <Modal
        footer={null}
        open={isModalOpenDateRange}
        onOk={handleOkDateRange}
        onCancel={handleCancelDateRange}
      >
        <DateRange
          className="w-full"
          // editableDateInputs={true}
          onChange={(item) => setState([item.selection])}
          // moveRangeOnFirstSelection={false}
          ranges={state}
        />
      </Modal>
      <Modal
        open={isModalOpenDetailClass}
        onOk={handleOkDetailClass}
        onCancel={handleCancelDetailClass}
        footer={null}
        width={700}
      >
        <div className="flex justify-between p-4 items-center">
          <span className="font-semibold text-[20px]">
            {selectedClassDetail.class_name}
          </span>
          {selectedClassDetail.is_online ? (
            <Tag color="#27AE60">Online</Tag>
          ) : (
            <Tag color="#2D9CDB">Onsite</Tag>
          )}
        </div>

        <div className="p-4 gap-8 text-[#475569]">
          <div className="flex  items-center">
            <label
              className="text-[20px] font-bold w-[40%]"
              style={{ color: studio_detail?.styles?.header_color }}
            >
              Schedule
            </label>
            <span className="text-[20px]">
              {moment(selectedClassDetail.class_date).format("LL")}{" "}
              {selectedClassDetail.time}
            </span>
          </div>
          <div className="flex  items-center">
            <label
              className="text-[20px] font-bold w-[40%]"
              style={{ color: studio_detail?.styles?.header_color }}
            >
              Teacher
            </label>
            <span className="text-[20px] font-semibold">
              {selectedClassDetail.teacher}
            </span>
          </div>
          <div className="flex  items-center">
            <label
              className="text-[20px] font-bold w-[40%]"
              style={{ color: studio_detail?.styles?.header_color }}
            >
              Slots
            </label>
            <span className="text-[20px]">
              {selectedClassDetail.slots} Remaining
            </span>
          </div>
          <div className="flex  items-center">
            <label
              className="text-[20px] font-bold w-[40%]"
              style={{ color: studio_detail?.styles?.header_color }}
            >
              Description
            </label>
            <span className="text-[20px]">{selectedClassDetail.notes} </span>
          </div>

          <div className="flex  items-center">
            <label
              className="text-[20px] font-bold "
              style={{ color: studio_detail?.styles?.header_color }}
            >
              What You’ll Need
            </label>
            <span> </span>
          </div>

          <div className="flex w-full justify-center">
            <Button
              type="primary"
              title="Enroll Now"
              style={{
                backgroundColor:
                  studio_detail?.styles?.header_color || "#E26851",
                borderRadius: 0,
                marginTop: 24,
              }}
              onClick={redirectToLogin}
            />
          </div>
        </div>
      </Modal>
    </OrganismLayout>
  );
};

export default PublicClassList;
