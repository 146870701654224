import axios from "../../axiosInstance";
import OwnerActionTypes from "./owner.types";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../services/generate-error-message";

const addStudentUrl = "/students";
const addTeacherUrl = "/teachers";
const addClassUrl = "/classes";
const getTeacherUrl = "/teachers";
const addPlanUrl = "/plans";
const getStudentsUrl = "/students";

export const getOnboardingStatus = (token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_ONBOARDING_STATUS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get("/onboarding", config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_ONBOARDING_STATUS_SUCCESS,
          payload: res?.data?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: OwnerActionTypes.GET_ONBOARDING_STATUS_FAILED,
        message: error.response.data?.message,
      });
    }
  };
};

export const addStudent = (params, token_jwt, studio_id) => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.ADD_STUDENT,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.post(addStudentUrl, formData, config);
      dispatch({
        type: OwnerActionTypes.ADD_STUDENT_SUCCESS,
        payload: res?.data.data,
      });
      res?.data.is_account_active !== 0 &&
        toast.success("Successfully Add Student!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.ADD_STUDENT_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStudentDetail = (token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_DETAIL,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(`${getStudentsUrl}/${id}`, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STUDENT_DETAIL_SUCCESS,
          payload: res?.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_DETAIL_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStudentClass = (token_jwt, studio_id, id, extraParams) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.get(
        `${getStudentsUrl}/${id}/class?${extraParams}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STUDENT_CLASS_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_CLASS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStudentPlan = (token_jwt, studio_id, id, extraParams) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_PLAN,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${getStudentsUrl}/${id}/plan?${extraParams}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STUDENT_PLAN_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_PLAN_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStudentNotes = (token_jwt, studio_id, id, extraParams) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_NOTES,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${getStudentsUrl}/${id}/notes?${extraParams}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STUDENT_NOTES_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_NOTES_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStudentOrder = (token_jwt, studio_id, id, extraParams) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_ORDER,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${getStudentsUrl}/${id}/order?${extraParams}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STUDENT_ORDER_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STUDENT_ORDER_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const updateStudent = (params, token_jwt, studio_id, id) => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.UPDATE_STUDENT,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.post(`${addStudentUrl}/${id}`, formData, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.UPDATE_STUDENT_SUCCESS,
          payload: res?.data.data[0],
        });
      }
      toast.success("Successfully Updated!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.UPDATE_STUDENT_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const addStudentNotes = (params, token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.ADD_STUDENT_NOTES,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.post(
        `${addStudentUrl}/${id}/notes`,
        params,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.ADD_STUDENT_NOTES_SUCCESS,
          payload: res?.data.data[0],
        });
      }
      toast.success("Successfully!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.ADD_STUDENT_NOTES_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const updateStudentNotes = (params, token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.ADD_STUDENT_NOTES,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.put(
        `${addStudentUrl}/notes/${id}`,
        params,
        config
      );

      dispatch({
        type: OwnerActionTypes.ADD_STUDENT_NOTES_SUCCESS,
        payload: res?.data.data[0],
      });
      toast.success("Successfully!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.ADD_STUDENT_NOTES_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const addTeacher = (params, token_jwt, studio_id) => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.ADD_TEACHER,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(addTeacherUrl, formData, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.ADD_TEACHER_SUCCESS,
          payload: res?.data,
        });
      }
      res?.data.is_account_active !== 0 &&
        toast.success("Successfully Add Teacher!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.ADD_TEACHER_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getTeacher = (token_jwt, studio_id, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_TEACHERS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(`${getTeacherUrl}?limit=0`, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_TEACHERS_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: OwnerActionTypes.GET_TEACHERS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const updateTeacher = (params, token_jwt, studio_id, id) => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return async (dispatch) => {
    dispatch({
      type: OwnerActionTypes.UPDATE_TEACHER,
    });

    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(`${getTeacherUrl}/${id}`, formData, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.UPDATE_TEACHER_SUCCESS,
          payload: res?.data.data,
        });
        toast.success("Successfully Updated!");
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.UPDATE_TEACHER_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getTeacherDetail = (token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_TEACHER_DETAIL,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(`${getTeacherUrl}/${id}`, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_TEACHER_DETAIL_SUCCESS,
          payload: res?.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_TEACHER_DETAIL_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getClass = (token_jwt, studio_id, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(`${addClassUrl}?limit=0`, config);
      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_CLASS_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_CLASS_FAILED,
        message: error.response?.data?.message,
      });
    }
  };
};

export const getClassDetail = (token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_CLASS_DETAIL,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(`${addClassUrl}/${id}`, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_CLASS_DETAIL_SUCCESS,
          payload: res?.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_CLASS_DETAIL_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getClassTimeParticipant = (
  token_jwt,
  studio_id,
  id,
  class_date
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_CLASS_TIME_PARTICIPANT,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `classes/${id}/class-date/${class_date}/time`,
        config
      );

      dispatch({
        type: OwnerActionTypes.GET_CLASS_TIME_PARTICIPANT_SUCCESS,
        payload: res?.data.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_CLASS_TIME_PARTICIPANT_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getClassParticipant = (
  token_jwt,
  studio_id,
  id,
  page,
  student_name,
  class_date,
  start_time,
  end_time
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_CLASS_PARTICIPANT,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${addClassUrl}/${id}/participants?page=${page}&student_name=${student_name}&class_date=${
          class_date === "Invalid date" ? "" : class_date
        }&start_time=${start_time ?? ""}&end_time=${end_time ?? ""}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_CLASS_PARTICIPANT_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_CLASS_PARTICIPANT_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const updateStatusAttended = (token_jwt, studio_id, params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.UPDATE_STATUS_ATTENDED,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(`${addClassUrl}/attendance`, params, config);
      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.UPDATE_STATUS_ATTENDED_SUCCESS,
          payload: res?.data.data,
        });
      }
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.UPDATE_STATUS_ATTENDED_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const cancelClasses = (token_jwt, studio_id, id) => {
  return async () => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      await axios.post(`order/${id}/cancel`, "", config);

      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const updatePaymentStatus = (
  token_jwt,
  studio_id,
  params,
  order_number
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.UPDATE_PAYMENT_STATUS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.put(`/orders/${order_number}`, params, config);
      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.UPDATE_PAYMENT_STATUS_SUCCESS,
          payload: res?.data.data,
        });
      }
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.UPDATE_PAYMENT_STATUS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const updateClass = (params, token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.UPDATE_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.patch(`${addClassUrl}/${id}`, params, config);
      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.UPDATE_CLASS_SUCCESS,
          payload: res?.data.data,
        });
      }
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.UPDATE_CLASS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const updateSchedule = (params, token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_UPDATED_SCHEDULE,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(
        `${addClassUrl}/${id}/schedules`,
        params,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_UPDATED_SCHEDULE_SUCCESS,
          payload: res?.data,
        });
      }
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_UPDATED_SCHEDULE_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const deleteClass = (token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.ACTIVATE_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      await axios.delete(`${addClassUrl}/${id}`, config);

      dispatch({
        type: OwnerActionTypes.ACTIVATE_CLASS_SUCCESS,
      });

      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.ACTIVATE_CLASS_FAILED,
      });
    }
  };
};

export const changeStatusClass = (token_jwt, studio_id, id, status) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.ACTIVATE_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(
        `${addClassUrl}/${id}/${status}`,
        {},
        config
      );
      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.ACTIVATE_CLASS_SUCCESS,
        });
      }
      toast.success("Success!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.ACTIVATE_CLASS_FAILED,
      });
    }
  };
};

export const addClass = (params, token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.ADD_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(addClassUrl, params, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.ADD_CLASS_SUCCESS,
          payload: res?.data.data,
        });
      }
      toast.success("Class Successfully Added!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.ADD_CLASS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getAllPlans = (token_jwt, studio_id, plan_type, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_PLANS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${addPlanUrl}?plan_type=${plan_type}&limit=0`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_PLANS_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_PLANS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getDetailPlans = (token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_DETAIL_PLANS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(`${addPlanUrl}/${id}`, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_DETAIL_PLANS_SUCCESS,
          payload: res?.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_DETAIL_PLANS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const addPlan = (params, token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.ADD_PLAN,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(addPlanUrl, params, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.ADD_PLAN_SUCCESS,
          payload: res?.data?.data[0],
        });
      }
      toast.success("Successfully added a plan!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.ADD_PLAN_FAILED,
        message: error.response?.data?.message,
      });
    }
  };
};

export const updatePlan = (params, token_jwt, studio_id, id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.UPDATE_PLAN,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(`${addPlanUrl}/${id}`, params, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.UPDATE_PLAN_SUCCESS,
          payload: res?.data?.data[0],
        });
      }
      toast.success("Plan Successfully Updated!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.UPDATE_PLAN_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const deletePlan = (token_jwt, studio_id, id) => {
  return async () => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      await axios.delete(`${addPlanUrl}/${id}`, config);
      toast.success("Plan Successfully Deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const deactivePlan = (token_jwt, studio_id, id, status) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.DEACTIVE_PLANS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.post(
        `${addPlanUrl}/${id}?status=${status}`,
        { status: "non active" },
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.DEACTIVE_PLANS_SUCCESS,
          payload: res?.data?.data,
        });
      }
      toast.success("Plan Successfully Deactivated!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.DEACTIVE_PLANS_FAILED,
        message: error.response?.data?.message,
      });
    }
  };
};

export const getPaymentDetail = (token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_PAYMENT_DETAIL,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get("/payments", config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_PAYMENT_DETAIL_SUCCESS,
          payload: res?.data?.data,
        });
      }
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_PAYMENT_DETAIL_FAILED,
        message: error?.response?.data.message,
      });
    }
  };
};

export const addUpdatePayment = (params, token_jwt, studio_id) => {
  return async () => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      await axios.post("/disbursements", params, config);

      toast.success("Successfully !");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const setclassNameSetup = (params) => {
  return (dispatch) => {
    dispatch({
      type: OwnerActionTypes.CLASSNAME_SETUP,
      payload: params,
    });
  };
};

export const addSchedule = (params) => {
  return (dispatch) => {
    dispatch({
      type: OwnerActionTypes.ADD_SCHEDULE,
      payload: params,
    });
  };
};

export const removeSchedule = (params) => {
  return (dispatch) => {
    dispatch({
      type: OwnerActionTypes.REMOVE_SCHEDULE,
      payload: params,
    });
  };
};

export const addDuration = (params) => {
  return (dispatch) => {
    dispatch({
      type: OwnerActionTypes.ADD_DURATION,
      payload: params,
    });
  };
};

export const addIdTeacher = (params) => {
  return (dispatch) => {
    dispatch({
      type: OwnerActionTypes.ADD_IDTEACHER,
      payload: params,
    });
  };
};

export const removeIdTeacher = (params) => {
  return (dispatch) => {
    dispatch({
      type: OwnerActionTypes.REMOVE_IDTEACHER,
      payload: params,
    });
  };
};

export const clearAddTeacherRes = () => {
  return (dispatch) => {
    dispatch({
      type: OwnerActionTypes.CLEAR_IDTEACHER,
    });
  };
};

export const setStatusDefault = () => {
  return (dispatch) => {
    dispatch({
      type: OwnerActionTypes.SET_STATUS_DEFAULT,
    });
  };
};

export const getStudents = (params, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STUDENTS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${params.token_jwt}`,
          "Studio-Id": params.studio_id,
        },
      };

      const res = await axios.get(`${getStudentsUrl}?limit=0`, config);

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STUDENTS_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STUDENTS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getTimezones = (token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get("/timezones", config);
      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_TIME_ZONE_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const addLocations = (params, token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      await axios.post("/locations", params, config);

      toast.success("Successfully add locations!");
      dispatch({
        type: OwnerActionTypes.ADD_LOCATIONS_SUCCESS,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      dispatch({
        type: OwnerActionTypes.ADD_LOCATIONS_DEFAULT,
      });
    }
  };
};

// Dashboard
const dashboardOwnerUrl = "/dashboard-owners";

export const getStatisticProfile = (
  token_jwt,
  studio_id,
  start_date,
  end_date
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_PROFILE,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${dashboardOwnerUrl}?start_date=${start_date}&actiontype=statistic-request&end_date=${end_date}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STATISTIC_PROFILE_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_PROFILE_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStatisticPaymentVerification = (
  token_jwt,
  studio_id,
  start_date,
  end_date,
  page
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_PAYMENT,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${dashboardOwnerUrl}?start_date=${start_date}&page=${page}&actiontype=payment-verification&end_date=${end_date}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STATISTIC_PAYMENT_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_PAYMENT_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStatisticCancellation = (
  token_jwt,
  studio_id,
  start_date,
  end_date,
  page
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_CANCELLATION,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${dashboardOwnerUrl}?start_date=${start_date}&page=${page}&actiontype=cancellation-request&end_date=${end_date}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STATISTIC_CANCELLATION_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_CANCELLATION_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStatisticUpcomingClass = (
  token_jwt,
  studio_id,
  start_date,
  end_date,
  page
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_UPCOMING_CLASS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${dashboardOwnerUrl}?start_date=${start_date}&page=${page}&actiontype=upcoming-classes&end_date=${end_date}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STATISTIC_UPCOMING_CLASS_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_UPCOMING_CLASS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};

export const getStatisticClassInProgress = (
  token_jwt,
  studio_id,
  start_date,
  end_date,
  page
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_CLASS_IN_PROGRESS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `${dashboardOwnerUrl}?start_date=${start_date}&page=${page}&actiontype=class-in-progress&end_date=${end_date}`,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: OwnerActionTypes.GET_STATISTIC_CLASS_IN_PROGRESS_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: OwnerActionTypes.GET_STATISTIC_CLASS_IN_PROGRESS_FAILED,
        message: error?.response?.data?.message,
      });
    }
  };
};
