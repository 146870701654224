import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./login.styles.scss";

import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import Footer from "../../components/Footer/footer.component";
import TextInput from "../../components/TextInput/text-input.component";
import logo from "./../../assets/images/gigs-logo.svg";
import {
  resendOtp,
  verifyOtp,
  setLoginFromStudio,
  setStatusDefault,
  setStatusVerifyDefault,
  activateAccount,
  requestOtpActivate,
} from "../../redux/user/user.action";
import { getStudioInfo } from "../../services/studios";

const VerifyOtp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [remainTime, setRemainTime] = useState(60);
  const [studioDetail, setStudioDetail] = useState({});

  const {
    status,
    user,
    // isLoading,
    login_email,
    loginFromStudio,
    login_data,
    studio_detail,
  } = useSelector((state) => state.user);
  const users = useSelector((state) => state.user);

  const isReactivate = props.location.state?.isReactivate;
  const subdomain = window.location.host.split(".")[0];
  const emailFromRegister = props.location.state?.emailFromRegister;

  useEffect(() => {
    handleGetStudioInfo();
  }, []);

  useEffect(() => {
    if (!remainTime) return;
    const timer = setInterval(() => {
      setRemainTime(remainTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [remainTime]);

  useEffect(() => {
    if (status === "success") {
      dispatch(setStatusDefault());
      setRemainTime(60);
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (user?.token && user?.role === "superadmin") {
      history.push("/banks");
      dispatch(setStatusVerifyDefault());
      return;
    }

    if (user?.token && subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
      history.replace("/choose-studio");
      dispatch(setStatusVerifyDefault());
    }
  }, [dispatch, history, subdomain, user?.role, user?.token]);

  useEffect(() => {
    if (user?.token && loginFromStudio) {
      history.replace("/dashboard");
    }
  }, [user?.token, history, loginFromStudio]);

  useEffect(() => {
    console.log({ login_email, user, users });
  }, [login_email, user, users]);

  const handleGetStudioInfo = async () => {
    try {
      const res = await getStudioInfo(subdomain);
      setStudioDetail(res.data.data);
    } catch (error) {
      console.log(error.config);
      console.error("Error:", error.message);
    }
  };

  const resendCode = () => {
    if (isReactivate) {
      dispatch(
        requestOtpActivate(
          {
            email: user?.email ?? login_email,
            role: login_data?.role ?? "student",
          },
          subdomain
        )
      );
    } else {
      if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
        dispatch(resendOtp(user?.email ?? login_email, null));
      } else {
        dispatch(resendOtp(user?.email ?? login_email, subdomain));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      email: user?.email ?? login_email,
      password: Number(otp),
    };
    if (isReactivate) {
      dispatch(
        activateAccount(
          {
            email: user?.email ?? login_email,
            role: login_data?.role ?? "student",
            otp: otp,
          },
          subdomain
        )
      );
      dispatch(setLoginFromStudio());
    } else {
      if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
        dispatch(verifyOtp(params, null));
      } else {
        dispatch(verifyOtp(params, subdomain));
        dispatch(setLoginFromStudio());
      }
    }
  };

  return (
    <Container fluid className="bg-light">
      <Row style={{ minHeight: "100vh" }}>
        <Form onSubmit={handleSubmit}>
          <Col
            lg={4}
            className="mx-auto d-flex align-items-center justify-content-center flex-column p-5"
          >
            <img
              alt=""
              src={studioDetail?.logo_link || logo}
              style={{ width: 80 }}
            />
            <span className="fw-bold mt-2 mb-5">
              {studioDetail?.studio_name || "GIGSCLASSROOM"}
            </span>
            <h4
              className="fw-bold text-center"
              style={{ fontFamily: "Roboto" }}
            >
              Verify Your Email Address
            </h4>
            <p className="regular-text text-center mb-3mt-3">
              We emailed you 6 (six) digits code to{" "}
              <span
                style={{ color: studioDetail?.styles?.header_color || "#000" }}
                className="font-semibold"
              >
                {user?.email ?? login_email}.
              </span>{" "}
              Enter the code below to confirm your email address
            </p>
            <TextInput
              autoFocus
              required
              min={6}
              max={6}
              type="number"
              placeholder="000000"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              style={{
                fontSize: "24px",
                fontFamily: "Roboto",
                color: "#000",
                textAlign: "center",
                width: "50%",
                margin: "0 auto",
              }}
              className="mt-3"
            />
            <p className="regular-text text-center mb-3 mt-3 fst-italic">
              The verification code above is only valid for 30 minutes. Please
              don’t share this code with anyone, including those on behalf of
              GigsClass
            </p>
            <div className="regular-text text-center my-3">
              Didn't receive the code?{" "}
              {!remainTime ? (
                <u
                  className="fw-bold"
                  style={{
                    color: studioDetail?.styles?.header_color || "#E26851",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={resendCode}
                >
                  Resend Code
                </u>
              ) : (
                <span
                  className="fw-bold"
                  style={{
                    color: studioDetail?.styles?.header_color || "#E26851",
                  }}
                >
                  Resend Code in {remainTime} Second.
                </span>
              )}
            </div>
            <Button
              buttonType="submit"
              type="primary"
              title="VERIFY"
              // isLoading={isLoading}
              style={{
                backgroundColor:
                  studioDetail?.styles?.header_color || "#E26851",
                borderRadius: 0,
                marginTop: 24,
              }}
            />
          </Col>
        </Form>
      </Row>
      <Footer />
    </Container>
  );
};

export default VerifyOtp;
