import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import "./../classes.styles.scss";

import { Col } from "react-bootstrap";
import Button from "../../../../../components/Button/button.component";
import { Button as AntButton, Form } from "antd";

import {
  addSchedule,
  addDuration,
} from "../../../../../redux/owner/owner.action";
import ScheduleFormlist from "../../../../../components/ScheduleFormlist";
import DatePickerCustom from "../../../../../components/DatePickerCustom";
import dayjs from "dayjs";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const ClassDurationSetup = ({
  prevStepSchedule,
  nextStepSchedule,
  id,
  setParams,
}) => {
  const [form] = Form.useForm();

  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);

  const [repeatAmount, setRepeatAmount] = useState(1);
  const [repeatDuration, setRepeatDuration] = useState("weeks");
  const [stopRepeating, setstopRepeating] = useState("after");
  const [schedules, setSchedules] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [payload, setPayload] = useState({});

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { addClass, class_detail } = useSelector((state) => state.owner);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const isCreating = window.location.pathname.includes("add") ? true : false;
  const colorTheme = studio_detail?.styles?.header_color;

  useEffect(() => {
    if (!isCreating) {
      setRepeatAmount(class_detail.repeat?.repeat_amount);
      setRepeatDuration(class_detail.repeat?.repeat_duration);
      setstopRepeating(class_detail.repeat?.stop_repeating);

      if (schedules.length > 0) {
        // Function to filter and normalize schedules
        const normalizeSchedules = (schedules) => {
          return schedules
            .filter(
              ({ start_time, end_time }) =>
                dayjs(start_time, "HH:mm", true).isValid() &&
                dayjs(end_time, "HH:mm", true).isValid()
            )
            .map(({ day, start_time, end_time }) => ({
              day: day.toLowerCase(),
              start_time,
              end_time,
            }));
        };

        // Normalize both sets of schedules
        const validApiSchedules = normalizeSchedules(class_detail?.schedules);
        const validSubmitSchedules = normalizeSchedules(schedules);

        // Merge schedules, allowing duplicates for the same day
        const mergedSchedules = [...validApiSchedules, ...validSubmitSchedules];

        setSchedules(mergedSchedules);
      }
    }
  }, [isCreating, class_detail, schedules]);

  useEffect(() => {
    if (isReady && schedules.length > 0 && Object.entries(payload).length > 0) {
      dispatch(addSchedule(schedules));
      dispatch(addDuration(payload));
      nextStepSchedule();
    }
  }, [dispatch, isReady, nextStepSchedule, payload, schedules]);

  const generateInitialValues = (days, isCreating, addClass, class_detail) => {
    return days.reduce((acc, day) => {
      const daySchedules = isCreating
        ? addClass.schedule.filter(
            (schedule) => schedule.day.toLowerCase() === day.toLowerCase()
          )
        : class_detail?.schedules?.filter(
            (schedule) => schedule.day.toLowerCase() === day.toLowerCase()
          );

      const validSchedules = daySchedules?.filter((schedule) => {
        const isValidStart = dayjs(
          schedule.start_time,
          "HH:mm",
          true
        ).isValid();
        const isValidEnd = dayjs(schedule.end_time, "HH:mm", true).isValid();
        return isValidStart && isValidEnd;
      });

      acc[day] =
        validSchedules?.length > 0
          ? validSchedules.map((schedule) => ({
              startTime: schedule.start_time,
              endTime: schedule.end_time,
            }))
          : [{ startTime: "", endTime: "" }];
      acc[`${day}Checked`] = validSchedules?.length > 0;
      return acc;
    }, {});
  };

  const initialValues = generateInitialValues(
    days,
    isCreating,
    addClass,
    class_detail
  );

  const handleSubmit = (value) => {
    setIsReady(false);

    const arrSchedule = [];
    const ids = [];

    const isTimeSlotIncluded = (day, startTime, endTime) => {
      const timeSlotId = `${day}${startTime}-${endTime}`;
      return ids.includes(timeSlotId);
    };

    const processDaySchedule = (day, daySchedule) => {
      daySchedule.forEach(({ startTime, endTime }) => {
        const dayLowerCase = day.toLowerCase();

        if (
          startTime &&
          endTime &&
          dayjs(startTime, "HH:mm", true).isValid() &&
          dayjs(endTime, "HH:mm", true).isValid()
        ) {
          if (isTimeSlotIncluded(dayLowerCase, startTime, endTime)) {
            setIsReady(false);
            toast.error("Please Select Different Time!");
          } else {
            setIsReady(true);
            const timeSlotId = `${dayLowerCase}${startTime}-${endTime}`;
            ids.push(timeSlotId);
            arrSchedule.push({
              day: dayLowerCase,
              start_time: dayjs(startTime).format("HH:mm"),
              end_time: dayjs(endTime).format("HH:mm"),
            });
          }
        }
      });
    };

    days.forEach((day) => {
      if (value[day] && value[day].length > 0) {
        processDaySchedule(day, value[day]);
      }
    });

    const formatDate = (date) => {
      if (!date) return "";
      const { year, month, day } = date;
      return `${year}-${month}-${day}`;
    };

    const finalStartDate = formatDate(value.start_date);
    const finalEndDate = formatDate(value.end_date);

    const params = {
      start_date: finalStartDate,
      end_date: finalEndDate,
      repeat_every: repeatAmount,
      repeat_duration: repeatDuration,
    };

    setSchedules(arrSchedule);
    setPayload(params);
  };

  return (
    <Col lg={7}>
      <Form form={form} initialValues={initialValues} onFinish={handleSubmit}>
        <h6 className="text-slate-900 text-lg font-bold my-[25px]">Period</h6>
        <Form.Item label="Start date" name="start_date">
          <DatePickerCustom currentDate={class_detail?.class_start_date} />
        </Form.Item>

        <Form.Item label="End date" name="end_date">
          <DatePickerCustom
            currentDate={
              class_detail?.class_end_date !== ""
                ? class_detail?.class_end_date
                : class_detail?.class_start_date
            }
          />
        </Form.Item>

        <h6 className="text-slate-900 text-lg font-bold my-[25px]">
          Schedules
        </h6>
        {days.map((day) => (
          <ScheduleFormlist
            key={day}
            name={day}
            colorTheme={colorTheme}
            form={form}
          />
        ))}

        <div className="w-full flex flex-col gap-3">
          <Button
            buttonType="submit"
            type="primary"
            title="Next"
            style={{ width: "100%", backgroundColor: colorTheme }}
          />

          <AntButton
            type="text"
            htmlType="reset"
            style={{
              color: colorTheme,
            }}
            size="large"
            className="w-full rounded-sm"
            onClick={() => {
              isCreating ? prevStepSchedule() : history.push("/classes");
            }}
          >
            Back
          </AntButton>
        </div>
      </Form>
    </Col>
  );
};

export default ClassDurationSetup;
